import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { createUserRolePermission, getUserRoleDetails } from "../../redux/services/accountSetting";
import AccessTable from "./accessTable";
import { Button, InputField } from "../../components";
import { ACCOUNT_SETTINGS, ERROR_STRINGS, INPUT_FIELDS, REGEX_STRINGS } from "../../utils/constant";
import axiosInstance from "../../redux/axios";
import endPoints from "../../redux/services/endPoints.json";
import { getOtpforUpdate } from "../../redux/services/settings";
import { clearOTPData } from "../../redux/slices/settingSlice";
import useMessageHook from "../../hooks/useMessageHook";
import SuccessPopup from "./successPopup";
import { DeleteIcon } from "../../assets/images/icons";

const CreateRollForm = () => {
  const [value, setValue] = useState<any>({});
  const [error, setError] = useState<any>({});
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const { showMessage, contextHolder } = useMessageHook();
  const navigate = useNavigate();
  
  const dispatch = useAppDispatch();
  const params = useParams();
  const { userDetails, selectedRole } = useAppSelector((state) => state.accountSetting);
  const { otpVerify } = useAppSelector((state) => state.setting);

  useEffect(() => {
    if (params?.userId) {
      dispatch(getUserRoleDetails(params?.userId));
    }
  }, [dispatch, params?.userId]);

  useEffect(() => {
    if (params?.userId && !userDetails?.isLoading && userDetails?.isSuccess) {
      setValue({
        f_name: userDetails?.data?.userRole?.user?.firstName,
        l_name: userDetails?.data?.userRole?.user?.lastName,
        email: userDetails?.data?.userRole?.user?.email,
        phone_number: userDetails?.data?.userRole?.user?.phoneNo,
        role: userDetails?.data?.userRole?.roleName,
      });
    }
  }, [params, userDetails]);

  useEffect(() => {
    if (!otpVerify.isLoading && otpVerify.isSuccess) {
      setIsPhoneNumberVerified(true);
    }
  }, [otpVerify]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      dispatch(clearOTPData());
      setIsPhoneNumberVerified(false);
      setIsUpdated(true);
      if (REGEX_STRINGS.NUMBER.test(value)) {
        setValue((prev: any) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setIsPhoneNumberVerified(true);
      setValue((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleValidate = () => {
    const errorObj = {};
    setError({});
    const isValidPhoneNumber = REGEX_STRINGS.PHONE_NUMBER.test(value?.phone_number);
    if (!value?.f_name) {
      Object.assign(errorObj, { f_name: true });
    }
    if (!value?.l_name) {
      Object.assign(errorObj, { l_name: true });
    }
    if (!value?.phone_number || value?.phone_number?.length !== 10) {
      Object.assign(errorObj, { phone_number: true });
    }
    if (value?.phone_number?.length === 10 && !isValidPhoneNumber) {
      Object.assign(errorObj, { phone_number_message: true });
    }
    if (value?.email && !REGEX_STRINGS.EMAIL.test(value?.email)) {
      Object.assign(errorObj, { email: true });
    }
    if (!value?.role) {
      Object.assign(errorObj, { role: true });
    }
    if (Object.keys(errorObj)?.length) {
      setError(errorObj);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (handleValidate()) {
      if (isPhoneNumberVerified) {
        const payload = {
          firstName: value?.f_name || "",
          lastName: value?.l_name || "",
          email: value?.email || "",
          phoneNo: value?.phone_number || "",
          roleName: value?.role || "",
          moduleRights: selectedRole || [],
        };
        
        if (!params?.userId) {
          const response = await dispatch(createUserRolePermission(payload));
          if (response?.payload?.status === 200) {
            showMessage("success", response?.payload?.message);
            navigate(-1);
          } else {
            showMessage("error", response?.payload?.message || ERROR_STRINGS.SOMETHING_WRONG);
          }
        } else {
          Object.assign(payload, { userId: userDetails?.data?.userRole?.user?.userId });
          await axiosInstance.post(endPoints.editUserRolePermission, payload).then((result) => {
            if (result?.data?.status === 200) {
              showMessage("success", result?.data?.message);
              navigate(-1);
              dispatch(clearOTPData());
            }
          }).catch((error) => {
            console.log(error);
          });
        }
      } else if (value?.phone_number !== userDetails?.data?.userRole?.user?.phoneNo && !isPhoneNumberVerified) {
        showMessage("error", ERROR_STRINGS.PHONE_NUMBER);
      } else if (isUpdated && !isPhoneNumberVerified) {
        const payload = {
          firstName: value?.f_name || "",
          lastName: value?.l_name || "",
          email: value?.email || "",
          phoneNo: value?.phone_number || "",
          roleName: value?.role || "",
          moduleRights: selectedRole || [],
        };
        
        if (!params?.userId) {
          await dispatch(createUserRolePermission(payload)).then((result: any) => {
            if (result?.data?.status === 200) {
              setIsUserCreated(true);
              setTimeout(() => {
                setIsUserCreated(false);
                showMessage("success", result?.data?.message);
                navigate(-1);
                dispatch(clearOTPData());
              }, 2000);
            }
          }).catch(() => showMessage("error", ERROR_STRINGS.SOMETHING_WRONG));
        } else {
          Object.assign(payload, { userId: userDetails?.data?.userRole?.user?.userId });
          await axiosInstance.post(endPoints.editUserRolePermission, payload).then((result) => {
            if (result?.data?.status === 200) {
              setIsUserCreated(true);
              setTimeout(() => {
                setIsUserCreated(false);
                showMessage("success", result?.data?.message);
                navigate(-1);
                dispatch(clearOTPData());
              }, 2000);
            }
          }).catch((error) => {
            console.log(error);
          });
        }
      }
    }
  };

  const sendOTP = async () => {
    await dispatch(getOtpforUpdate(value?.phone_number)).then((result) => {
      if (result?.payload?.status === 400) {
        showMessage("error", result?.payload?.message);
        dispatch(clearOTPData());
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  
  return (
    <div className="create_user_roll_form">
      {contextHolder}
      <div className="form-title-container">
        <div className="flex justifyBetween alignCenter">
          <div className="flex alignCenter gap-2">
            <button className="back-button" onClick={() => navigate(-1)}><LeftOutlined /></button>
            <h3 className="form-title">
              {params?.userId ? ACCOUNT_SETTINGS.CREATE_USER_ROLL_FORM.FORM_TITLE_1 : ACCOUNT_SETTINGS.CREATE_USER_ROLL_FORM.FORM_TITLE_2}
            </h3>
          </div>
          {params?.userId ? <div className="buttons-container">
            <Button type="button" className="rounded-12 text delete-button"><DeleteIcon />Remove User</Button>
          </div> : <></>}
        </div>
      </div>
      <div className="form-input-container gap-4 flex direction-column">
        <div className="flex gap-4">
          <InputField
            name="f_name"
            label={INPUT_FIELDS.FIRST_NAME.LABEL}
            placeholder={INPUT_FIELDS.FIRST_NAME.PLACEHOLDER}
            onChange={handleChange}
            value={value?.f_name}
            required
            className={error?.f_name ? "error" : ""}
          />
          <InputField
            name="l_name"
            label={INPUT_FIELDS.LAST_NAME.LABEL}
            placeholder={INPUT_FIELDS.LAST_NAME.PLACEHOLDER}
            onChange={handleChange}
            value={value?.l_name}
            required
            className={error?.l_name ? "error" : ""}
          />
        </div>
        <InputField
          name="phone_number"
          label={INPUT_FIELDS.PHONE_NUMBER.LABEL}
          placeholder={INPUT_FIELDS.PHONE_NUMBER.PLACEHOLDER}
          onChange={handleChange}
          value={value?.phone_number}
          required
          verify={value?.phone_number === userDetails?.data?.userRole?.user?.phoneNo || (!otpVerify?.isLoading && otpVerify?.isSuccess) ? null : "phone"}
          className={error?.phone_number ? "error" : ""}
          handleVerifyPhone={sendOTP}
        />
        <InputField
          name="email"
          type="email"
          label={INPUT_FIELDS.EMAIL.LABEL}
          placeholder={INPUT_FIELDS.EMAIL.PLACEHOLDER}
          onChange={handleChange}
          value={value?.email}
          className={error?.email ? "error" : ""}
          errorMessage={error?.email ? ERROR_STRINGS.INVALID_EMAIL : ""}
        />
        <InputField
          name="role"
          type="text"
          label={INPUT_FIELDS.CREATE_ROLE.LABEL}
          placeholder={INPUT_FIELDS.CREATE_ROLE.PLACEHOLDER}
          onChange={handleChange}
          value={value?.role}
          className={error?.role ? "error" : ""}
          required
        />
      </div>
      {isUserCreated ? <SuccessPopup type={params?.userId ? "update" : "create"} /> : ""}
      {userDetails?.isLoading ? <div className="page-loader"><Spin /></div> : ""}
      <AccessTable setIsUpdated={setIsUpdated} />
      <div className="form-footer flex alignCenter justifyEnd">
        <div className="buttons-container flex alignCenter gap-3">
          <button className="cancel rounded-14" type="button" onClick={() => { navigate(-1); dispatch(clearOTPData()); }}>Cancel</button>
          <button className="submit primary rounded-14" type="submit" onClick={handleSubmit} disabled={!isUpdated}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default CreateRollForm;