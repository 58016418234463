import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { DatePicker, message, PaginationProps, Popover, Table, TableProps, Tabs, TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

import { getConnectedCompanyOrder, getConnectionDetailsById, getConnectionList } from "../../redux/services/connection";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import NoDataPage from "./noDataPage";
import { Button } from "../../components";
import { StopIcon } from "../../assets/images";
import { DeleteIcon, NoImagesIcon, UnblockIcon, UserIcon, PlusFilledIcon, ChevronDown } from "../../assets/images/icons";
import ThreeDots from "../../assets/images/threeDots";
import { BlockUnblockRemoveConnection } from "../../redux/services/vendor";
import { formatRupees } from "../../utils/helper";
import StatusSwitch from "../orders/statusSwitch";
import { tableSortingIconToggle } from "../../utils/tableSortingIconToggle";
import SearchBar from "../../components/searchBar";
import { getOrderItemsByOrder } from "../../redux/services/orders";
import { debounce } from "../../utils/debounce";
import DispatchOrderModal from "../orders/dispatchOrderModal";
import TransportationDetails from "../orders/transportationDetails";
import useMessageHook from "../../hooks/useMessageHook";
import Pagination from "../../components/pagination";
import { setConnectionOrdersPagination } from "../../redux/slices/paginationSlice";
import socket from "../../socket";
import { clearCompanyOrder, clearConnectionList, updatedConnectionDetail, updatedConnectionList } from "../../redux/slices/connectionSlice";
import InviteModal from "./inviteModal";
import { dispatchOrderItemsTypes } from "../../types/ordersTypes";
import PartiallyRecievedModal from "../orders/partiallyRecievedModal";
import ConnectionConfirmationModal from "../../components/ConnectionConfirmationModal";
import { ERROR_STRINGS } from "../../utils/constant";
import ImageLoad from "../../components/ImageLoad";
import { CleverTapEvents } from "../../utils/clevertapEvents";

interface dispatchType {
  isDispatch: boolean;
  orderId: number | null;
  isTransport: boolean
}
interface ConfirmationType {
  isToggle: boolean;
  type: string;
  connectionId: number;
}

const ConnectionDetails = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [sort, setSort] = useState({
    order: "ASC",
    field: "createdOn",
  });
  const [date, setDate] = useState<{
    startDate: string,
    endDate: string,
  }>({
    startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
    endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
  });

  const [activeTab, setActiveTab] = useState(1);
  const [searchOrder, setSearchOrder] = useState<string>("");
  const [loading, setLoading] = useState({
    block_unblock: false,
    orders: false,
  });
  const [tableRowCount, setTableRowCount] = useState(10);
  const [orderTableHeight, setTableHeight] = useState(0);
  const [isPartiallyDispatched, setIsPartiallyDispatched] = useState({
    isOpen: false,
    orderId: 0,
    orderNo: 0,
  });

  const [toggleDispatch, setToggleDispatch] = useState<dispatchType>({ isDispatch: false, isTransport: false, orderId: null });
  const [connectionConfirmation, setConnectionConfirmation] = useState<ConfirmationType>({} as ConfirmationType);
  const { showMessage, contextHolder } = useMessageHook();

  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const tableHeight = document.querySelector(".orders-content .ant-tabs")?.clientHeight;

  const { connectionDetails, connection, companyOrders, connectionActiveTab, connectionPayload } = useAppSelector(state => state.connections);
  const { statusDetails } = useAppSelector(state => state.manageOrders);
  const { connectionOrdersPagination } = useAppSelector(state => state.pagination);
  const { orderItems } = useAppSelector(state => state.manageOrders);
  const { userDetails } = useAppSelector(state => state.common);
  const [dispatchOrderItems, setDispatchOrderItems] = useState<dispatchOrderItemsTypes[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);

  const companyId = userDetails.data?.company?.companyId;

  const navigate = useNavigate();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const updateTableHeight = () => {
      const calculatedHeight = window.innerHeight - 390;
      setTableHeight(calculatedHeight);
    };

    updateTableHeight();

    window.addEventListener("resize", updateTableHeight);
    return () => window.removeEventListener("resize", updateTableHeight);
  }, []);

  useEffect(() => {
    setSearchOrder("");
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [connectionDetails]);

  const tableBody = document.querySelector(".ant-table-body");

  useEffect(() => {
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  }, [connectionOrdersPagination, tableBody]);

  useEffect(() => {
    dispatch(clearCompanyOrder());
    if (Number(params?.id) && params.id !== "undefined") {
      try {
        dispatch(getConnectionDetailsById(Number(params?.id))).then((result) => {
          if (result?.payload?.status === 200) {
            CleverTapEvents.trackEvent("Connection Details viewed", {
              name: result.payload?.data?.connection?.name,
              isBlock: result.payload?.data?.connection?.isBlock,
              isVendor: result.payload?.data?.connection?.isVendor,
              connectionId: result.payload?.data?.connection?.connectionId,
            });
          }
          if (result?.payload?.status === 404) {
            navigate("/connection");
            ListOfAllConnection();
            showMessage("error", result?.payload.message);
          }
        }).catch((error) => {
          showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
        });
      } catch (error) {
        showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
      }
    } else {
      showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
      navigate("/connection");
    }
    if (location?.state?.invite) {
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params?.id]);

  const ListOfAllConnection = async () => {
    dispatch(clearConnectionList());
    const payload = {
      search: searchOrder,
      sort: sort?.order,
      type: connectionActiveTab,
      limit: connectionPayload?.data,
      offset: 0,
    };
    try {
      await dispatch(getConnectionList(payload));
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const UpdateConnectionList = async (type: string) => {
    if (type === "remove") {
      const connectionData = connection.data.filter((item) => item?.connectionId !== Number(params?.id));
      dispatch(updatedConnectionList(connectionData));
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (connectionDetails?.data?.connection?.companyId) {
      setLoading((prev) => ({
        ...prev,
        orders: true,
      }));
      dispatch(getConnectedCompanyOrder({
        connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
        endDate: date.endDate,
        startDate: date.startDate,
        search: searchOrder,
        sort: [sort],
        type: activeTab,
        limit: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount),
        offset: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount) * (connectionOrdersPagination.currentPage - 1),
      })).then(() => {
        setLoading((prev) => ({
          ...prev,
          orders: false,
        }));
      }).catch((error) => {
        showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, connectionDetails?.data?.connection?.companyId, activeTab, date, sort, connectionOrdersPagination]);

  useEffect(() => {
    if (statusDetails.isSuccess) {
      if (connectionDetails?.data?.connection?.companyId) {
        setLoading((prev) => ({
          ...prev,
          orders: true,
        }));
        dispatch(getConnectedCompanyOrder({
          connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
          endDate: date.endDate,
          startDate: date.startDate,
          search: searchOrder,
          sort: [sort],
          type: activeTab,
          limit: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount),
          offset: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10 ? connectionOrdersPagination.pageSize : tableRowCount) * (connectionOrdersPagination.currentPage - 1),
        })).then(() => {
          setLoading((prev) => ({
            ...prev,
            orders: false,
          }));
        }).catch((error) => {
          showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDetails.isSuccess]);

  const connectedCompanyOrderPayload = useMemo(() => ({
    connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
    endDate: date.endDate,
    startDate: date.startDate,
    search: searchOrder,
    sort: [sort],
    type: activeTab,
    limit: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10
      ? connectionOrdersPagination.pageSize
      : tableRowCount),
    offset: (tableRowCount < connectionOrdersPagination.pageSize || connectionOrdersPagination.pageSize === 10
      ? connectionOrdersPagination.pageSize
      : tableRowCount) * (connectionOrdersPagination.currentPage - 1),
  }), [
    connectionDetails?.data?.connection?.companyId,
    date,
    searchOrder,
    sort,
    activeTab,
    tableRowCount,
    connectionOrdersPagination,
  ]);

  useEffect(() => {
    const handleOrderStatusChanged = (data: any) => {
      if (data && connectionDetails?.data?.connection?.companyId) {
        dispatch(getConnectedCompanyOrder(connectedCompanyOrderPayload));
      }
    };

    const handleConnectionUpdated = (data: any) => {
      if (data.flag) {
        const updatedConnectionDetailData = { ...connectionDetails.data?.connection, isBlockFromOtherSide: !connectionDetails?.data?.connection?.isBlockFromOtherSide };
        const updatedData = { ...connectionDetails.data, connection: updatedConnectionDetailData };

        dispatch(updatedConnectionDetail(updatedData));
      }
    };

    if (activeTab === 0) {
      socket.on(`myOrderstatusChanged_${companyId}`, handleOrderStatusChanged);
    }
    socket.on(`orderstatusChanged_${companyId}`, handleOrderStatusChanged);
    socket.on(`connectionBlockUnblock_${companyId}`, handleConnectionUpdated);

    return () => {
      socket.off(`myOrderstatusChanged_${companyId}`, handleOrderStatusChanged);
      socket.off(`orderstatusChanged_${companyId}`, handleOrderStatusChanged);
      socket.off(`connectionBlockUnblock_${companyId}`, handleConnectionUpdated);
    };
  }, [socket, connectedCompanyOrderPayload, dispatch, companyId, activeTab, connectionDetails]);

  const blockUnblockRemoveConnection = async (value: string) => {
    setLoading((prev) => ({
      ...prev,
      block_unblock: true,
    }));
    await dispatch(BlockUnblockRemoveConnection({
      connectionId: Number(params?.id) || 0,
      action: value,
    })).then((result) => {
      if (result?.payload?.status === 200 && value !== "Remove") {
        setConnectionConfirmation((prev) => ({ ...prev, isToggle: false }));
        showMessage("success", result?.payload?.message);

        const data = { ...connectionDetails.data?.connection, isBlock: value === "Block" ? true : false };
        const updatedData = { ...connectionDetails.data, connection: data };
        dispatch(updatedConnectionDetail(updatedData));
        const updateList = connection.data.map((item: { connectionId: number; }) => {
          return (
            item.connectionId === Number(params.id) ? { ...item, isBlock: value === "Block" ? true : false } : item
          );
        });
        dispatch(updatedConnectionList(updateList));
        setLoading((prev) => ({
          ...prev,
          block_unblock: false,
        }));
      } else {
        setConnectionConfirmation((prev) => ({ ...prev, isToggle: false }));
        if (value === "Remove") {
          dispatch(clearConnectionList());
          try {
            UpdateConnectionList("remove");
            navigate("/connection");
          } catch (error: any) {
            message.error(error?.message);
          }
        }
      }
    }).catch(err => {
      setLoading((prev) => ({
        ...prev,
        block_unblock: true,
      }));
      showMessage("error", err?.message);
    });
    setIsPopoverOpen(false);
  };

  const orderItemsColumns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (_, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.product?.productImage?.thumbnailURL ? <ImageLoad className="product-image" src={record?.product?.productImage?.thumbnailURL} alt="product-img" iconType="product" /> : <NoImagesIcon />}
          </div>
          <span className="product-name">{record?.product?.productName}</span>
        </div>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 159,
      render: (_, record: any) => (
        <div className="size-text">{record?.productSize?.productSize} {record?.productSize?.productSizeUnit}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (_, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.qty}</span>
          <span className="unit">{record?.quantityOrdered} {record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
  ];

  const getOrderedProducts = async (id: number) => {
    await dispatch(getOrderItemsByOrder(id));
  };

  const OrderItemContent = () => {
    return (
      <Table
        columns={orderItemsColumns}
        dataSource={orderItems.data || []}
        // dataSource={[]}
        className="order-items-table"
        pagination={false}
      />
    );
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().add(0, "d"), dayjs()] },
    { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
    { label: "This Week", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last Week", value: [dayjs().add(-14, "d"), dayjs().add(-7, "d")] },
    { label: "This Month", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "This Year", value: [dayjs().add(-1, "y"), dayjs()] },
  ];

  const columns = [
    {
      title: "Order No",
      dataIndex: "orderId",
      key: "orderNumber",
      sorter: true,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (text: string, record: any) => {
        return (
          <p className="caption" onClick={() => navigate(`/orders/my-orders/${record?.orderId}`, { state: { activeTab: 1 } })}>
            {record?.orderNumber ? `${record?.orderNumber}` : "-"}
          </p>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{dayjs(record?.createdOn).format("DD/MM/YYYY")}</p>
        );
      },
    },
    {
      title: "Ordered Items",
      dataIndex: "orderItem",
      key: "orderItem",
      render: (_: string, record: any) => {
        return (
          <Popover
            content={OrderItemContent}
            trigger="click"
            placement="bottomLeft"
            rootClassName="order-item-popover"
          >
            <button className="ordered-items-btn gap-1 flex alignCenter" onClick={() => getOrderedProducts(record?.orderId)}>
              {record?.orderItemsCount} items <ChevronDown />
            </button>
          </Popover>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortIcon: (sortOrder: any) => tableSortingIconToggle(sortOrder),
      render: (_: string, record: any) => {
        return (
          <StatusSwitch
            status={record?.status === "In Progress" ? "inProgress" : record?.status}
            orderId={record?.orderId}
            onDispatch={() => setToggleDispatch({ orderId: record?.orderId, isDispatch: true, isTransport: false })}
            activeTab={activeTab === 1 ? "Customer-order" : "My-order"}
            isPartiallyReceived={record.dispatchesCount > 1 ? true : (record.dispatchesCount === 1 && record?.status === "Partially Dispatched") ? true : false}
            onPartiallyReceived={() => setIsPartiallyDispatched(() => ({ isOpen: true, orderId: record?.orderId, orderNo: record?.orderNumber }))}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_: string, record: any) => {
        return (
          <p className="caption">{formatRupees(record?.grandTotal)}</p>
        );
      },
    },
  ];

  const onRangeChange = (dates: null | (any | null)[], dateStrings: string[]) => {
    if (dates) {
      setDate({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setDate({
        startDate: (dayjs(new Date()).subtract(3, "months").format("DD/MM/YYYY")),
        endDate: (dayjs(new Date()).format("DD/MM/YYYY")),
      });
    }
  };

  const handlePagination = (data: any) => {
    const payload = {
      pageSize: data?.pageSize,
      currentPage: data?.page,
    };
    dispatch(setConnectionOrdersPagination(payload));
  };

  const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") {
      return <button className="prev-button pagination-button flex alignCenter gap-2"><ArrowLeftOutlined /> Previous</button>;
    }
    if (type === "next") {
      return <button className="next-button pagination-button flex alignCenter gap-2">Next <ArrowRightOutlined /></button>;
    }
    return originalElement;
  };

  const items: any = [
    {
      key: "1",
      label: (
        <span className="tab-label flex gap-2">
          Received Orders
        </span>
      ),
      children: (
        activeTab === 1 ? <div>
          <Table
            columns={columns}
            dataSource={companyOrders?.data?.orders || []}
            pagination={false}
            className="orders-table"
            onChange={(pagination, filter, sorter: any) => {
              setSort({
                order: sorter?.order === "descend" ? "DESC" : "ASC",
                field: sorter?.columnKey || "createdOn",
              });
            }}
            scroll={{ x: "min-content", y: orderTableHeight }}
            loading={loading.orders || orderItems.isLoading}
          />
          {(companyOrders.data?.totalOrders >= 10) ?
            <div className="flex justifyEnd pagination-wrapper">
              <Pagination
                pageSize={connectionOrdersPagination.pageSize}
                pageCount={tableRowCount}
                showSizeChanger={true}
                total={companyOrders.data?.totalOrders || 0}
                current={connectionOrdersPagination.currentPage}
                onChange={handlePagination}
                itemRender={itemRender}
              />
            </div> : <></>}
        </div> : <></>
      ),
    },
    {
      key: "0",
      label: (
        <span className="tab-label flex gap-2">
          Placed Orders
        </span>
      ),
      children: (
        activeTab === 0 ? <div>
          <Table
            columns={columns}
            dataSource={companyOrders.data?.orders || []}
            pagination={false}
            className="orders-table"
            onChange={(pagination, filter, sorter: any) => {
              setSort({
                order: sorter?.order === "descend" ? "DESC" : "ASC",
                field: sorter?.columnKey || "createdOn",
              });
            }}
            scroll={{ x: "min-content", y: orderTableHeight }}
            loading={loading.orders || orderItems.isLoading}
          />
          {(companyOrders.data?.totalOrders >= 10) ?
            <div className="flex justifyEnd pagination-wrapper">
              <Pagination
                pageSize={connectionOrdersPagination.pageSize}
                pageCount={tableRowCount}
                showSizeChanger={true}
                total={companyOrders.data?.totalOrders || 0}
                current={connectionOrdersPagination.currentPage}
                onChange={handlePagination}
                itemRender={itemRender}
              />
            </div> : <></>}
        </div> : <></>
      ),
    },
  ];

  // Debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchProducts = useCallback(
    debounce((query: string, tableRowCount: number, pageSize: number, currentPage: number) => {
      if (connectionDetails?.data?.connection?.companyId) {
        setLoading((prev) => ({
          ...prev,
          orders: true,
        }));
        dispatch(getConnectedCompanyOrder({
          connectedCompanyId: connectionDetails?.data?.connection?.companyId || 0,
          endDate: date.endDate,
          startDate: date.startDate,
          search: query,
          sort: [sort],
          limit: (tableRowCount < pageSize || pageSize === 10 ? pageSize : tableRowCount),
          offset: (tableRowCount < pageSize || pageSize === 10 ? pageSize : tableRowCount) * (currentPage - 1),
          type: activeTab,
        })).then(() => {
          setLoading((prev) => ({
            ...prev,
            orders: false,
          }));
        }).catch((error) => {
          showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
        });
      }
    }, 500),
    [dispatch, connectionDetails?.data?.connection?.companyId],
  );

  useEffect(() => {
    setTableRowCount(Math.floor((tableHeight || 0) / 57));
  }, [connectionOrdersPagination.pageSize, tableHeight, activeTab]);

  const handleSearchOrder = (query: string) => {
    setSearchOrder(query);
    debouncedFetchProducts(searchOrder, tableRowCount, connectionOrdersPagination.pageSize, connectionOrdersPagination.currentPage);
  };

  const handleActiveTab = (key: string) => {
    setActiveTab(Number(key));
    const payload = {
      pageSize: tableRowCount,
      currentPage: 1,
    };
    dispatch(setConnectionOrdersPagination(payload));
  };

  const handleBlockOpen = (block: boolean) => {
    if (!block) {
      setConnectionConfirmation({ isToggle: true, type: "Block", connectionId: Number(params?.id) });
      setIsPopoverOpen(false);
    } else {
      blockUnblockRemoveConnection("Unblock");
    }
  };
  const handleRemove = () => {
    setIsPopoverOpen(false);
    setConnectionConfirmation({ isToggle: true, type: "Remove", connectionId: Number(params?.id) });
  };

  return (
    <>
      {contextHolder}
      <div className="connection-details w-100" ref={scrollRef}>
        <div className={(connection?.data.length > 0) ? "card" : "no-data-screen h-100"}>
          {(connection?.data?.length > 0) ?
            <div className="details-card gap-8">
              <div className="profile-image-container">
                <div className="profile-image flex justifyCenter alignCenter">
                  {connectionDetails?.isLoading ? <Skeleton circle width={180} height={180} /> : connectionDetails?.data?.connection?.profileImg ? <ImageLoad src={connectionDetails?.data?.connection?.profileImg} alt="avatar" /> : <UserIcon />}
                </div>
              </div>
              <div className="profile-description">
                <div className="product-summary-section">
                  <div className="summary">
                    <h3 className="title-3">{connectionDetails?.isLoading ? <Skeleton width={200} /> : connectionDetails?.data?.connection?.name}</h3>
                    <p className="caption">{connectionDetails?.isLoading ? <Skeleton width={120} /> : connectionDetails?.data?.connection?.aboutCompany}</p>
                  </div>
                </div>
                <div className="address-connection-section">
                  <p className="address-text caption">
                    {connectionDetails?.isLoading ? <Skeleton width={180} /> : <>{connectionDetails?.data?.connection?.city || ""}, {connectionDetails?.data?.connection?.state || ""}, India.</>}
                  </p>
                  <button className="connections-count">
                    {connectionDetails?.isLoading ? <Skeleton width={100} /> : <>{connectionDetails?.data?.connection?.connectionsCount} Connections</>}
                  </button>
                </div>
                <div className="orders-message-container">
                  <div className="orders-details">
                    <div className="received-order-details">
                      <p className="caption">Received Order</p>
                      <h4 className="title-4">{connectionDetails?.isLoading ? <Skeleton width={60} /> : (connectionDetails?.data?.connection?.receivedOrders || "0")}</h4>
                    </div>
                    <div className="placed-order-details">
                      <p className="caption">Placed Order</p>
                      <h4 className="title-4">{connectionDetails?.isLoading ? <Skeleton width={60} /> : (connectionDetails?.data?.connection?.placedOrders || "0")}</h4>
                    </div>
                  </div>
                  <div className="messages-container gap-3">
                    {connectionDetails?.isLoading ? <Skeleton width={139} height={36} /> : <>
                      {((connectionDetails?.data?.connection?.isVendor && (!connectionDetails?.data?.connection?.isBlock)) && (!connectionDetails?.data?.connection?.isBlockFromOtherSide)) &&
                        <Button
                          onClick={() => {
                            navigate("/placeorder", {
                              state: { orderId: connectionDetails?.data?.connection?.companyId, companyName: connectionDetails?.data?.connection?.name, companyProfile: connectionDetails?.data?.connection?.profileImg, username: ((connectionDetails.data?.connection?.createdBy?.firstName || "") + (connectionDetails.data?.connection?.createdBy?.lastName || "")) },
                            });
                            CleverTapEvents.trackEvent("Place Order Click - Connection");
                          }} className="placeorder-button primary large" type="button">
                          <PlusFilledIcon /> Place Order
                        </Button>}
                    </>}
                    <Popover
                      content={() => (
                        <div>
                          {
                            <button className="block" disabled={loading?.block_unblock || false} onClick={() => handleBlockOpen(connectionDetails?.data?.connection?.isBlock || false)}>
                              {connectionDetails?.data?.connection?.isBlock ?
                                <><UnblockIcon /> Unblock</> :
                                <><StopIcon /> Block</>
                              }
                            </button>
                          }
                          <button disabled={loading.block_unblock} className="remove" onClick={() => handleRemove()}><DeleteIcon />Remove</button>
                        </div>
                      )}
                      open={isPopoverOpen}
                      onOpenChange={() => setIsPopoverOpen(!isPopoverOpen)}
                      trigger="click"
                      rootClassName="block-or-remove-container"
                      placement="bottomRight"
                    >
                      {connectionDetails?.isLoading ? <Skeleton width={36} height={36} /> : <button type="button" className="square-button"><ThreeDots /></button>}
                    </Popover>
                  </div>
                </div>
              </div>
            </div> :
            <NoDataPage onClick={showModal} />}
        </div>

        {connection.data.length ?
          <div className="card orders-content">
            <Tabs
              defaultActiveKey="1"
              items={items}
              activeKey={activeTab?.toString()}
              tabBarExtraContent={
                <div className="tabs-right-order-actions flex gap-4">
                  <SearchBar
                    onChange={(e) => handleSearchOrder(e.target.value)}
                    value={searchOrder}
                    placeholder="Search Order"
                  />
                  <div className="date-picker">
                    <RangePicker
                      presets={rangePresets}
                      onChange={onRangeChange}
                      height={36}
                      width={400}
                      placement="bottomRight"
                      format="DD/MM/YYYY"
                      defaultValue={[dayjs(new Date()).subtract(3, "months"), dayjs(new Date())]}
                      allowClear={false}
                      maxDate={dayjs(new Date())}
                    />
                  </div>
                </div>
              }
              onChange={(key) => { handleActiveTab(key); }}
            />
          </div> : <></>}
      </div>
      {toggleDispatch?.isDispatch &&
        <DispatchOrderModal
          open={toggleDispatch?.isDispatch}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
          handleConfirm={() => setToggleDispatch({ isDispatch: false, isTransport: true, orderId: toggleDispatch.orderId })}
          setDispatchOrderItems={(data: dispatchOrderItemsTypes[]) => setDispatchOrderItems(data)}
        />}
      {toggleDispatch?.isTransport &&
        <TransportationDetails
          open={toggleDispatch.isTransport}
          close={() => setToggleDispatch({ isDispatch: false, isTransport: false, orderId: null })}
          orderId={toggleDispatch.orderId || 0}
          dispatchOrderItems={dispatchOrderItems}
        />}
      {isPartiallyDispatched.isOpen &&
        <PartiallyRecievedModal
          close={() => setIsPartiallyDispatched(() => ({ isOpen: false, orderId: 0, orderNo: 0 }))}
          open={isPartiallyDispatched.isOpen}
          orderId={isPartiallyDispatched.orderId}
          orderNo={isPartiallyDispatched.orderNo}
        />}
      {visible ?
        <InviteModal
          setVisible={setVisible}
          visible={visible}
        /> : <></>}
      {connectionConfirmation.isToggle ?
        <ConnectionConfirmationModal
          type={connectionConfirmation.type}
          open={connectionConfirmation.isToggle}
          connectionId={connectionConfirmation.connectionId}
          close={() => setConnectionConfirmation((prev) => ({ ...prev, isToggle: false }))}
          blockUnblockRemoveConnection={({ action }) => blockUnblockRemoveConnection(action)}
          actionLoading={loading.block_unblock}
          modalType="Connection"
        /> : ""}
    </>
  );
};

export default ConnectionDetails;