import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { MoreIconsDots, PlusIcons } from "../../assets/images";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getUserRoleList } from "../../redux/services/accountSetting";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getProfileIcon } from "../../utils/helper";
import { clearOTPData } from "../../redux/slices/settingSlice";
import { clearForm } from "../../redux/slices/accountSettingSlice";


interface ISettingCardProps {
  data: {
    roleId: number,
    roleName: string,
    user: {
      firstName: string,
      lastName: string
    }
  }
}


const AccountsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { usersList } = useAppSelector((state) => state.accountSetting);

  useEffect(() => {
    dispatch(getUserRoleList());
    dispatch(clearOTPData());
    dispatch(clearForm());
  }, [dispatch]);

  const EmptyCard = () => {
    return (
      <div className="settings-card">
        <div className="user-empty-card flex direction-column justifyCenter alignCenter gap-2" onClick={() => navigate(`/settings/${params?.id}/user-and-permission/createUser`)}>
          <button className="add-button">
            <PlusIcons />
          </button>
          <span className="text fontWeight-600">Create User</span>
        </div>
      </div>
    );
  };



  const UserRoleCard = ({ data }: ISettingCardProps) => {
    return (
      <div className="userRole-card">
        {/* <button className="more-option">
          <MoreIconsDots />
        </button> */}
        <div className="card-data flex direction-column justifyCenter alignCenter gap-2" onClick={() => navigate(`/settings/${params?.id}/user-and-permission/user/${data?.roleId}`)}>
          <button className="profile">
            {getProfileIcon(data?.user?.firstName + " " + data?.user?.lastName)}
          </button>
          <span className="text fontWeight-600">{data?.user?.firstName + " " + data?.user?.lastName}</span>
          <div className="designation-button">{data?.roleName}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex gap-5 cards-container">
      <EmptyCard />
      {usersList?.data?.map((item: {
        roleId: number,
        roleName: string,
        user: {
          firstName: string,
          lastName: string
        }
      }, index) => {
        return (
          <UserRoleCard data={item} key={index} />
        );
      })}
    </div>
  );
};

export default AccountsList;