import React, { useEffect, useState } from "react";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { termsAndConditions } from "../../redux/services/common";
import { ERROR_STRINGS } from "../../utils/constant";

const TearmsAndConditions = () => {
  const [terms, setTerms] = useState("");
  const page = document.querySelector(".privacy-policy-page");

  const dispatch = useAppDispatch();

  useEffect(() => {
    page?.scrollIntoView(true);
  }, [page]);

  function decodeCfEmail(cfemail: string) {
    const r = parseInt(cfemail.substr(0, 2), 16);
    let email = "";
    for (let n = 2; n < cfemail.length; n += 2) {
      const charCode = parseInt(cfemail.substr(n, 2), 16) ^ r;
      email += String.fromCharCode(charCode);
    }
    return email;
  }

  useEffect(() => {
    // Decode and replace emails
    document.querySelectorAll(".__cf_email__").forEach((el: any) => {
      const cfemail = el?.getAttribute("data-cfemail");
      if (cfemail) {
        const decodedEmail = decodeCfEmail(cfemail);
        el.parentElement.href = `mailto:${decodedEmail}`;
        el.textContent = decodedEmail;
      }
    });
  }, [terms]);

  useEffect(() => {
    dispatch(termsAndConditions()).then((result) => setTerms(result.payload)).catch((error) => console.error(ERROR_STRINGS.SOMETHING_WRONG));
  }, [dispatch]);

  return (
    <div className="container privacy-policy-page">
      {/* <header className="info-heading-title">Terms and Conf</header> */}
      <div dangerouslySetInnerHTML={{ __html: terms }}></div>
    </div>
  );
};

export default TearmsAndConditions;