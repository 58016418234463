import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Popover, Spin, Tooltip } from "antd";
import OneSignal from "react-onesignal";

import socket from "../../socket";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { detectDevice, getDateLabel, getProfileIcon, groupDataByDate } from "../../utils/helper";
import { handleNotificationDrawer, setNotificationData } from "../../redux/slices/notificationSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { DiamondIcon, DownArrow, SettingOutlinedFilledIcon, UserFilledIcon } from "../../assets/images/icons";
import CompanyListAndSetting from "../companyListAndSetting";
import { BellOutlinedIcon, BiizlineLogo } from "../../assets/images";
import CountCapsule from "../countCapsule";
import { getSubscriptionPayloadData, getSubscriptionPlanDetails } from "../../redux/services/subscription";
import { ALTER_TEXT, COMPONENTS_STRINGS, SubscriptionModules } from "../../utils/constant";
import "../../assets/styles/common.scss";
import { getConnectionRequests, getSentRequest, takeActionOnConnectionRequest } from "../../redux/services/connection";
import RequestBox from "../../pages/dashboard/components/requestBox";
import { ReceivedRequestProps } from "../../types/connectionType";
import { getAllStatCount } from "../../redux/services/dashboard";
import NoDataFound from "../NoDataFound";
import { updateConnectionRequests } from "../../redux/slices/connectionSlice";
import ImageLoad from "../ImageLoad";

export const Header = () => {
  const [isToggleCompany, setToggleCompany] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState({
    isSetting: false,
    isConnection: false,
  });
  const [notificationCount, setNotificationCount] = useState({
    newNotifications: "",
    totalNotifications: "",
  });
  const [premiumButton, setPremiumButton] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navItems, setNavItems] = useState([
    {
      icon: "",
      text: <p>Home</p>,
      url: "/dashboard",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: <span className="flex justifyCenter alignCenter gap-2"><p>Orders</p></span>,
      url: "/orders",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: <p>Special Price List</p>,
      url: "/special-price-list",
      checked: true,
    },
    {
      icon: "",
      text: <span className="flex justifyCenter alignCenter gap-2"><p>Connections</p></span>,
      url: "/connection",
      checked: true,
    },
    {
      icon: "",
      text: <p>Products</p>,
      url: "/products",
      checked: true,
      isDisable: true,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const { receivedRequest } = useAppSelector(state => state.connections);

  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector(state => state.common);
  const { planDetails } = useAppSelector(state => state.subscription);
  const companyDetails = userDetails.data?.company;
  const { userId } = userDetails.data?.user || { userId: 0 };
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const activePlan = planDetails?.data?.plans?.filter((item: any) => {
      return item?.isActive;
    });
    if (activePlan?.[0]?.subscriptionId === 1) {
      setPremiumButton(true);
    } else {
      setPremiumButton(false);
    }
  }, [planDetails]);

  useEffect(() => {
    if (detectDevice()?.type === "Desktop" && userId && token) {
      dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY }));
      dispatch(getSubscriptionPlanDetails()).then((result) => {
        if (result?.payload?.status === 200) {
          setLoading(false);
        }
      }).catch((error) => {
        console.error(error);
        setLoading(false);
      });
      const payload = {
        search: "",
        offset: 0,
        limit: 0,
      };
      dispatch(getConnectionRequests(payload));
    }
  }, [dispatch, token, userId]);

  useEffect(() => {
    socket.emit("notificationHistory", companyDetails?.companyId);
    socket.emit("newNotificationCount", companyDetails?.companyId);
    socket.on("newNotificationCountResponse", (data: any) => {
      document.title = data ? `(${data}) Biizline` : "Biizline";
      setNotificationCount((prev) => ({
        newNotifications: data,
        totalNotifications: prev.totalNotifications,
      }));
    });

    socket.on("readNotificationResponse", (data: any) => {
      if (data?.isRead) {
        dispatch(setNotificationData(data));
        socket.emit("newNotificationCount", companyDetails?.companyId);
      } else {
        console.error("read notification error");
      }
    });

    socket.emit("allNotificationCount", companyDetails?.companyId);

    socket.on("allNotificationCountResponse", (data: any) => {
      setNotificationCount((prev) => ({
        newNotifications: prev.newNotifications,
        totalNotifications: data,
      }));
    });
    socket.on(`webDeviceLogedOut_${userId}`, (data) => {
      if (data) {
        OneSignal.logout();
        dispatch({ type: "RESET_STATE" });
        localStorage.clear();
        setTimeout(() => {
          navigate("/login", { state: { step: 0 } });
        }, 50);
      }
    });

    return () => {
      socket.off("newNotificationCountResponse");
      socket.off("readNotificationResponse");
      socket.off("allNotificationCountResponse");
      socket.off(`webDeviceLogedOut_${userId}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, companyDetails?.companyId, userId, dispatch]);

  const handleToggleNotification = () => {
    socket.emit("notificationHistory", companyDetails?.companyId);
    dispatch(handleNotificationDrawer());
  };

  const handleOpenChange = () => {
    setToggleCompany(!isToggleCompany);
  };

  const handlePopOverConnection = () => {
    setIsOpenPopover({
      isConnection: !isOpenPopover.isConnection,
      isSetting: isOpenPopover.isSetting,
    });
  };

  const handleChange = () => {
    setIsOpenPopover((prev) => ({
      ...prev,
      isSetting: !isOpenPopover.isSetting,
    }));
  };

  const getRecievedRequest = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
    };
    try {
      await dispatch(getConnectionRequests(payload));
    } catch (error) {
      console.log(error, "Received Request");
    }
  };

  const getSendedRequests = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
      forVendor: false,
    };
    try {
      await dispatch(getSentRequest(payload));
    } catch (error) {
      console.log(error, "Received Request");
    }
  };

  const requestAction = async (reqType: string, id: string | number) => {
    const payload = {
      requestId: id,
      action: reqType,
    };
    setLoading(true);
    await dispatch(takeActionOnConnectionRequest(payload)).then((result) => {
      if (result?.payload?.status === 200) {
        dispatch(getAllStatCount());
        const updateData = receivedRequest.data.filter((item) => item.requestId !== id);
        dispatch(updateConnectionRequests(updateData));

        if (reqType === "Cancel") {
          getSendedRequests();
        } else {
          getRecievedRequest();
        }
      } else {
        getRecievedRequest();
      }
    }).finally(() => setLoading(false));
  };

  // Group the receivedRequest data by date
  const receivedRequestGroupedData = receivedRequest?.data ? groupDataByDate(receivedRequest?.data || []) : {};

  const RequestsContent = (
    (Object.keys(receivedRequestGroupedData).length) ? (
      Object.entries(receivedRequestGroupedData).map(([date, items]: any) => (
        <div key={date}>
          <div className="notification-time">{getDateLabel(new Date(date))}</div>
          {items && items?.map((item: ReceivedRequestProps) => {
            return (
              <RequestBox
                actions={{ accept: true, reject: true }}
                content={{
                  companyId: item.sender?.companyId,
                  companyName: item.sender?.name,
                  img: item.sender?.profileThumbnailImg,
                  name: item.sender?.createdBy?.firstName + " " + item.sender?.createdBy?.lastName,
                  date: item.createdOn,
                  requestId: item.requestId,
                }}
                key={item.sender?.companyId}
                onClick={(data: string) => requestAction(data, item.requestId || 0)}
                disabled={loading}
              />
            );
          })}
        </div>
      ))
    ) : <NoDataFound text="No Connection Request Found" />
  );

  return (
    <>
      <header className="header flex alignCenter justifyBetween w-100">
        <div className="flex gap-4 justifyBetween alignCenter h-100 overflow-auto w-100 container">
          <NavLink to={"/"} className="logo-icon">
            <img src={BiizlineLogo} alt={ALTER_TEXT.LOGO} loading="lazy" />
          </NavLink>
          <nav className="navbar flex justifyStart">
            <ul className="flex gap-2 nav-items">
              {navItems && navItems?.map((item, index) => {
                return (
                  item.checked ? <li className="item relative" key={index}>
                    <NavLink to={item.url} className={"flex alignCenter justifyCenter"}>{item.icon}{item.text}</NavLink>
                  </li> : ""
                );
              })}
            </ul>
          </nav>
          <div className="flex alignCenter justifyEnd buttons-container gap-6 w-100">
            {premiumButton ? <div className="flex alignCenter get-premium-button" onClick={() => {
              navigate(`/settings/${companyDetails?.companyId}/subscribe/plans`);
            }}>
              <DiamondIcon />
              <span className="fontWeight-600">{COMPONENTS_STRINGS.HEADER.GET_PREMIUM}</span>
            </div> : <></>}
            <Tooltip title="No. of Connection Requests">
              {Object.entries(companyDetails || {}).length ?
                <Popover
                  content={RequestsContent}
                  trigger="click"
                  open={isOpenPopover.isConnection}
                  placement="bottomRight"
                  onOpenChange={handlePopOverConnection}
                  rootClassName="receivedRequest-Popup"
                >
                  <button className="invite-connection-button icon-button flex alignCenter justifyCenter">
                    <UserFilledIcon />
                    <CountCapsule count={receivedRequest?.data?.length} />
                  </button>
                </Popover> : <></>}
            </Tooltip>
            <Tooltip title="Notifications">
              {(companyDetails || Object.entries(companyDetails || {})?.length) ?
                <button className="notification-icon flex alignCenter relative" onClick={() => handleToggleNotification()}>
                  <BellOutlinedIcon />
                  {notificationCount?.newNotifications ?
                    <CountCapsule
                      count={Number(notificationCount?.newNotifications)}
                      limit={10}
                    /> : <></>}
                </button> : <></>}
            </Tooltip>
            <Tooltip title="Setting">
              {(companyDetails || Object.entries(companyDetails || {})?.length) ?
                <Popover
                  onOpenChange={handleChange}
                  open={isOpenPopover.isSetting}
                  rootClassName="select-company-popover"
                  content={() => CompanyListAndSetting({ handleOpenChange: handleChange, isSetting: true })}
                  trigger="click"
                  placement="bottomRight">
                  <button className="setting-icon icon-button relative">
                    <SettingOutlinedFilledIcon />
                    {/* <CountCapsule count={0} /> */}
                  </button>
                </Popover> : <></>}
            </Tooltip>
            {Object.entries(companyDetails || {}).length ?
              <Popover
                onOpenChange={handleOpenChange}
                open={isToggleCompany}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange })}
                trigger="click"
                placement="bottomRight">
                <button className="selected-company-btn gap-1">
                  {userDetails.isLoading ? <div className="loading-container"><Spin /></div> :
                    <>
                      <div className="image-container">
                        {companyDetails?.profileThumbnailImg ? <ImageLoad src={companyDetails.profileThumbnailImg} alt={ALTER_TEXT.COMPANY_IMAGE} /> : getProfileIcon(companyDetails?.name || "")}
                      </div>
                      <span className="company-name">{companyDetails?.name}</span>
                      <div className={isToggleCompany ? "action-dropdown active" : "action-dropdown"}>
                        <DownArrow />
                      </div>
                    </>}
                </button>
              </Popover> : <></>}
          </div>
        </div>
      </header>
    </>
  );
};