import clevertap from "clevertap-web-sdk";

export const cleverTapInit = () => {
  clevertap.init(process.env.REACT_APP_CLEVERTAP_KEY ?? "");
};

export const CleverTapEvents = {
  trackEvent: (eventName: string, eventData: Record<string, any> = {}) => {
    clevertap.event.push(eventName, eventData);
  },

  userLogin: (data: { name: string; userId: number; email: string; phoneNo: string; companyName: string, pincode: string, }) => {
    const eventData = {
      Name: data.name?.trim() || "Unknown",
      Identity: data.userId?.toString()?.trim(),
      Email: data.email?.trim() || "",
      Phone: `+91${data.phoneNo?.trim()}`,
      CompanyName: data.companyName?.trim() || "N/A",
      Pincode: data.pincode?.toString(),
    };

    clevertap.onUserLogin.push({
      Site: eventData,
    });
  },

  profileUpdate: (profileData: { name?: string; userId?: number; email?: string; phoneNo?: string; comapnyName?: string, pincode?: string, }) => {
    clevertap.profile.push({
      Site: profileData,
    });
  },

  pageView: (pageName: string) => {
    clevertap.event.push("Page View", { pageName });
  },
};
