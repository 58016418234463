import { createSlice } from "@reduxjs/toolkit";

import { getCompanyByPhoneNumber, getConnectedCompanyOrder, getConnectionDetailsById, getConnectionList, getConnectionRequests, getSentRequest } from "../services/connection";
import { ReceivedRequestProps } from "../../types/connectionType";

interface ConnectionDetailsResponseProps {
  isLoading: boolean,
  isSuccess: boolean,
  data: ConnectionDetailsProps | null,
  message: string,
}

interface ConnectionDetailsProps {
  connection: {
    companyId: number;
    connectionId: number;
    name: string;
    email: string | null;
    industryType: Array<string> | null,
    aboutCompany: string | null,
    profileImg: string | null,
    profileThumbnailImg: string | null,
    createdOn: string,
    modifiedOn: string | null,
    blockByCompany: number | null,
    isBlockFromOtherSide: boolean,
    isActive: boolean,
    isVerified: boolean,
    isBlockAsVendor: boolean;
    isBlockAsCustomer: boolean;
    isCustomer: boolean;
    isVendor: boolean;
    createdAt: string,
    updatedAt: string,
    isDelete: boolean;
    addressLine: string | null,
    orderNumberPrefix: string | null,
    orderNumberSuffix: string | null,
    isBlock: boolean;
    createdBy: {
      userId: number,
      firstName: string,
      lastName: string,
      email: string | null,
      role: string,
      phoneNo: string,
      isActive: boolean,
      isDelete: boolean,
      isVerified: boolean,
      createdAt: string,
      updatedAt: string
    },
    companyType: {
      businessTypeId: number,
      businessType: string,
      createdOn: string,
      modifiedOn: string,
      isActive: boolean,
      isDelete: boolean
    }[],
    connectionsCount: number,
    city: string,
    state: string,
    receivedOrders: string | number,
    placedOrders: string | number,
    pincode: string,
    district: string,
    country: string,
  }
}

export interface connectionDataProps {
  companyId: number
  connectionId: number;
  createdBy: { firstName: string, lastName: string }
  isBlock: boolean;
  isCustomer: boolean;
  isVendor: boolean;
  name: string
  profileThumbnailImg: string
}

interface connectionListProps {
  isLoading: boolean,
  isSuccess: boolean,
  data: connectionDataProps[],
  message: string,
  total: number,
  customers: number,
  vendors: number
}

interface InitialStateProps {
  connectionActiveTab: string,
  connection: connectionListProps,
  connectionDetails: ConnectionDetailsResponseProps,
  sentRequest: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: {
      requestId: number,
      createdOn: string,
      receiver?: {
        companyId: number,
        name: string
      },
      sender?: {
        companyId: number,
        name: string
      }
    }[]
  },
  receivedRequest: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: ReceivedRequestProps[]
  },
  companyList: {
    data: any,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  },
  companyOrders: {
    data: any,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  }
  connectionPayload: {
    data: any,
  }
}

const initialState: InitialStateProps = {
  connectionActiveTab: "All",
  connection: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
    total: 0,
    customers: 0,
    vendors: 0,
  },
  connectionDetails: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  sentRequest: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  receivedRequest: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  companyList: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  companyOrders: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  connectionPayload: {
    data: 0,
  },
};

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    getSentRequests: (state, { payload }) => {
      // state.sentRequest = payload;
    },
    clearCompanyList: (state) => {
      state.companyList = initialState.companyList;
    },
    clearConnectionDetail: (state) => {
      state.connectionDetails = initialState.connectionDetails;
    },
    clearCompanyOrder: (state) => {
      state.companyOrders = initialState.companyOrders;
    },
    setConnectionActiveTab: (state, { payload }) => {
      state.connectionActiveTab = payload;
    },
    connectionListPayload: (state, { payload }) => {
      state.connectionPayload.data = payload;
    },
    clearConnectionList: (state) => {
      state.connection.data = initialState.connection.data;
    },
    updatedConnectionList: (state, { payload }) => {
      state.connection.data = payload;
    },
    updatedConnectionDetail: (state, { payload }) => {
      state.connectionDetails.data = payload;
    },
    updateConnectionRequests: (state, { payload }) => {
      state.receivedRequest.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConnectionList.pending, (state) => {
      state.connection.isLoading = true;
      state.connection.isSuccess = false;
    });
    builder.addCase(getConnectionList.fulfilled, (state, { payload }: any) => {
      const existingData = state.connection.data.map(item => ({
        ...item,
      }));
      const newData = payload?.data?.connections.map((item: any) => ({
        ...item,
      }));

      const combinedData = Array.from(
        new Set([...existingData, ...newData].map(item => item.connectionId)),
      ).map(id =>
        [...existingData, ...newData].find(item => item.connectionId === id),
      );

      state.connection.isSuccess = true;
      state.connection.isLoading = false;
      state.connection.data = combinedData;
      state.connection.message = payload?.message;
      state.connection.total = payload?.data?.total;
      state.connection.customers = payload?.data?.customers;
      state.connection.vendors = payload?.data?.vendors;
    });
    builder.addCase(getConnectionList.rejected, (state, { payload }: any) => {
      state.connection.data = payload;
      state.connection.isSuccess = false;
      state.connection.isLoading = false;
      state.connection.message = payload?.message;
      state.connection.total = 0;
      state.connection.customers = 0;
      state.connection.vendors = 0;
    });
    builder.addCase(getConnectionDetailsById.pending, (state) => {
      state.connectionDetails.isLoading = true;
      state.connectionDetails.isSuccess = false;
    });
    builder.addCase(getConnectionDetailsById.fulfilled, (state, { payload }: any) => {
      state.connectionDetails.isSuccess = true;
      state.connectionDetails.isLoading = false;
      state.connectionDetails.data = payload?.data;
      state.connectionDetails.message = payload?.message;
    });
    builder.addCase(getConnectionDetailsById.rejected, (state, { payload }: any) => {
      state.connectionDetails.data = payload;
      state.connectionDetails.isSuccess = false;
      state.connectionDetails.isLoading = false;
      state.connectionDetails.message = payload?.message;
    });
    builder.addCase(getSentRequest.pending, (state) => {
      state.sentRequest.isLoading = true;
      state.sentRequest.isSuccess = false;
    });
    builder.addCase(getSentRequest.fulfilled, (state, { payload }: any) => {
      state.sentRequest.isSuccess = true;
      state.sentRequest.isLoading = false;
      state.sentRequest.data = payload?.data;
      state.sentRequest.message = payload?.message;
    });
    builder.addCase(getSentRequest.rejected, (state, { payload }: any) => {
      state.sentRequest.isSuccess = false;
      state.sentRequest.isLoading = false;
      state.sentRequest.data = payload?.data;
      state.sentRequest.message = payload?.message;
    });
    builder.addCase(getConnectionRequests.pending, (state) => {
      state.receivedRequest.isLoading = true;
      state.receivedRequest.isSuccess = false;
    });
    builder.addCase(getConnectionRequests.fulfilled, (state, { payload }: any) => {
      state.receivedRequest.isSuccess = true;
      state.receivedRequest.isLoading = false;
      state.receivedRequest.data = payload?.data;
      state.receivedRequest.message = payload?.message;
    });
    builder.addCase(getConnectionRequests.rejected, (state, { payload }: any) => {
      state.receivedRequest.isSuccess = false;
      state.receivedRequest.isLoading = false;
      state.receivedRequest.data = payload?.data;
      state.receivedRequest.message = payload?.message;
    });
    builder.addCase(getCompanyByPhoneNumber.pending, (state) => {
      state.companyList.isLoading = true;
      state.companyList.isSuccess = false;
    });
    builder.addCase(getCompanyByPhoneNumber.fulfilled, (state, { payload }: any) => {
      state.companyList.isSuccess = true;
      state.companyList.isLoading = false;
      state.companyList.data = payload?.data;
      state.companyList.message = payload?.message;
    });
    builder.addCase(getCompanyByPhoneNumber.rejected, (state, { payload }: any) => {
      state.companyList.isSuccess = false;
      state.companyList.isLoading = false;
      state.companyList.data = payload?.data;
      state.companyList.message = payload?.message;
    });
    builder.addCase(getConnectedCompanyOrder.pending, (state) => {
      state.companyOrders.isLoading = true;
      state.companyOrders.isSuccess = false;
    });
    builder.addCase(getConnectedCompanyOrder.fulfilled, (state, { payload }: any) => {
      state.companyOrders.isSuccess = true;
      state.companyOrders.isLoading = false;
      state.companyOrders.data = payload?.data;
      state.companyOrders.message = payload?.message;
    });
    builder.addCase(getConnectedCompanyOrder.rejected, (state, { payload }: any) => {
      state.companyOrders.isSuccess = false;
      state.companyOrders.isLoading = false;
      state.companyOrders.data = payload?.data;
      state.companyOrders.message = payload?.message;
    });
  },
});

export const { getSentRequests, clearCompanyList, clearConnectionDetail, clearCompanyOrder, setConnectionActiveTab, connectionListPayload, clearConnectionList, updatedConnectionList, updatedConnectionDetail, updateConnectionRequests } = connectionSlice.actions;
export default connectionSlice.reducer;