import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import axios from "axios";

import { getImage } from "./s3config";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

export const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>) => {
  const { key, target } = e;
  const { value, selectionStart } = target as HTMLInputElement;

  // Ensure selectionStart is not null
  if (selectionStart === null) return;

  // Prevent space at the start
  if (key === " " && selectionStart === 0) {
    e.preventDefault();
    return;
  }

  // Prevent multiple consecutive spaces
  if (key === " " && value[selectionStart - 1] === " ") {
    e.preventDefault();
    return;
  }

  // Prevent Enter if the last character is a newline
  if (key === "Enter" && value[value.length - 1] === "\n") {
    e.preventDefault();
  }
};

export const cleanValue = (val: string): string => val.replace(/[^0-9.]/g, "");

export const truncateString = (str: string, maxLength: number): string => {
  return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
};

export const convertToNumber = (value: string | number): number => {
  return Number(value);
};

export const getProfileIcon = (name: string): string => {
  const words = name?.split(" ");
  if (words?.length === 1) {
    return words[0].charAt(0).toUpperCase();
  } else if (words?.length > 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`;
  }
  return "";
};

export const formatRupees = (number: number, amount = true, isDecimal = true) => {
  return (
    !amount ? number?.toLocaleString("en-IN", {
      maximumSignificantDigits: 15,
    }) : new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: isDecimal ? 2 : 0,
      maximumFractionDigits: isDecimal ? 2 : 0,
    }).format(number)
  );
};

export const formatPercentage = (amount: any) => {
  return new Intl.NumberFormat("en-IN", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount / 100);
};

export const AddressFormat = (value: any) => {
  let address = "";
  if (!value?.addressLine) {
    address = `${value?.district}, ${value?.state}, ${value?.country}`;
  } else {
    address = `${value?.addressLine}, ${value?.district}, ${value?.state}, ${value?.pincode}`;
  }
  return address;
};

export function formatDate(inputDate: string): string {

  const date = dayjs(inputDate);

  // Format the date using dayjs
  const formattedDate = date.format("D MMMM, YYYY h:mmA");

  return formattedDate;
}

export const getRelativeTime = (timestamp: string | number | Date): string => {
  return dayjs(timestamp).fromNow();
};

export const getDateLabel = (date: Date) => {
  const today = dayjs().startOf("day");
  const yesterday = dayjs().subtract(1, "day").startOf("day");
  const notificationDate = dayjs(date).startOf("day");

  if (notificationDate.isSame(today)) {
    return "Today";
  } else if (notificationDate.isSame(yesterday)) {
    return "Yesterday";
  } else {
    return dayjs(date).format("DD MMMM YYYY");
  }
};

export const groupDataByDate = (data: any[]) => {
  const grouped: Record<string, any[]> = data.reduce((acc, item) => {
    const date = dayjs(item.createdOn).format("YYYY-MM-DD"); // Format to 'YYYY-MM-DD'
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {} as Record<string, any[]>);

  return Object.fromEntries(
    Object.entries(grouped).sort(([dateA], [dateB]) => dayjs(dateB).diff(dayjs(dateA))), // Sort by date in descending order
  );
};

export const formatDateTime = (dateString: string): string => {

  const date = dayjs(dateString);

  // Format date (Day, Month, Year)
  const formattedDate = date.format("DD MMMM, YYYY");

  // Format time (Hour:Minute AM/PM)
  const formattedTime = date.format("hh:mm A").toLowerCase();

  // Combine date and time
  return `${formattedDate} ${formattedTime}`;
};

// convert Date into time

export function ConvertDateToTime(dateInput: Date | string): string {
  const date = dayjs(dateInput);
  return date.format("hh:mm A");
}

export function calculateDiscount(
  mainRupees: number,
  value: number,
  isRateInRupees = false,
): string {
  // If the value is percentage, calculate the discounted price
  if (isRateInRupees) {
    // If the value is discounted rupees, calculate the percentage discount
    const discountPercentage = ((mainRupees - value) / mainRupees) * 100;
    return `In Percentage: ${mainRupees !== value ? (discountPercentage < 0 ? -discountPercentage.toFixed(2) + "% extra" : discountPercentage.toFixed(2) + "% less") : discountPercentage === 0 ? "0% discount" : "100% discount"}`; // Return discount percentage
  } else {
    const discountedPrice = mainRupees - (mainRupees * (value / 100));
    return `In Rupees: ${formatRupees(Number(discountedPrice.toFixed(2)))}`; // Return discounted price
  }
}

export function calculatePrice(oldPrice: number, newPrice?: number) {
  const price = oldPrice ?? 0; // Original price
  const percentage = newPrice ?? 0; // Percentage to subtract

  // Calculate the amount to subtract
  const amountToSubtract = (price * percentage) / 100;
  return formatRupees(newPrice ? price - amountToSubtract : oldPrice);
}

async function urlToFile(url: string, filename: string, mimeType: string): Promise<any> {
  if (url) {

    const imageBlob = await getImage((process.env.REACT_APP_AWS_BUCKET_NAME || ""), url);
    if (imageBlob) {
      const file = new File([imageBlob], filename, { type: mimeType });
      return file;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

async function convertUrlsToFiles(urls: string[]): Promise<File[]> {

  const files: File[] = [];

  for (const url of urls) {

    const filename = url.split("/").pop() || "image.jpg"; // Extract filename or use default

    const mimeType = `image/${filename.split(".").pop()?.toLowerCase()}` || "image/jpeg"; // Determine MIME type

    const file = await urlToFile(url, filename, mimeType);
    files.push(file);
  }

  return files;
}

export const handleImages = async (fileList: any) => {
  const fileteredImages: string[] = fileList?.length ? fileList?.filter((data: any) => typeof data.url === "string").map((data: { url: any; }) => data.url) : [];
  const data = await convertUrlsToFiles(fileteredImages);

  const NewFiledata: any = fileList?.length ? fileList?.filter((data: any) => data.originFileObj).map((data: any) => data.originFileObj) : [];

  const finalData = [...data, ...NewFiledata];

  return finalData;
};

export const FormatePrice = (value: number, round = 0, currency = true) => {
  const val = value;
  let newVal = "";
  if (val / 10000000 >= 1) {
    newVal = (val / 10000000)?.toFixed(round) + "Cr";
  } else if (val / 100000 >= 1) {
    newVal = (val / 100000)?.toFixed(round) + "L";
  } else if (val / 1000 >= 1 && val / 100000 < 10) {
    newVal = (val / 1000)?.toFixed(round) + "K";
  } else if (val < 0) {
    if ((0 - val) / 10000000 >= 1) {
      newVal = "-" + ((0 - val) / 10000000)?.toFixed(round) + "Cr";
    } else if ((0 - val) / 100000 >= 1) {
      newVal = "-" + ((0 - val) / 100000)?.toFixed(round) + "L";
    } else if ((0 - val) / 1000 >= 1 && (0 - val) / 100000 < 10) {
      newVal = "-" + ((0 - val) / 1000)?.toFixed(round) + "K";
    }
  } else {
    newVal = val?.toString();
  }
  if (!newVal) {
    return newVal;
  }
  if (currency) {
    newVal = "₹" + newVal;
  }
  return newVal;
};
export function convertChatMessage(date: string) {

  const ChatDate = dayjs(date);

  // Format the date to "Thursday 10:16am"
  const formattedDate = ChatDate.format("hh:mmA");
  return formattedDate;
}

export const getSectionLabel = (dateStr: string) => {
  const date = dayjs(dateStr);
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");

  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(yesterday, "day")) {
    return "Yesterday";
  } else {

    return date.format("MMMM D, YYYY");
  }
};

export const compressImage = (file: File, maxSizeKB = 400): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const compress = (scaleFactor: number) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const width = img.width * scaleFactor;
          const height = img.height * scaleFactor;

          canvas.width = width;
          canvas.height = height;

          ctx?.drawImage(img, 0, 0, width, height);

          return new Promise<Blob | null>((res) =>
            canvas.toBlob((blob) => res(blob), file.type, 0.6),
          );
        };

        const tryCompress = async (scaleFactor: number): Promise<File> => {
          let blob = await compress(scaleFactor);

          while (blob && blob.size / 1024 > maxSizeKB) {
            scaleFactor *= 0.9; // Reduce scale factor
            blob = await compress(scaleFactor);
          }

          if (blob) {
            return new File([blob], file.name, {
              type: blob.type,
              lastModified: Date.now(),
            });
          } else {
            throw new Error("Image compression failed.");
          }
        };

        tryCompress(Math.sqrt(maxSizeKB * 1024 / file.size))
          .then(resolve)
          .catch(reject);
      };
      img.src = event.target?.result as string;
    };

    reader.onerror = () => reject(new Error("Image loading failed."));
    reader.readAsDataURL(file);
  });
};

export const detectDevice = () => {
  const width = window.innerWidth;
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const devicePixelRatio = window.devicePixelRatio;

  // Consider device pixel ratio and dimensions for better detection
  if (isMobile || (devicePixelRatio > 1 && width <= 1024)) {
    if (/android/i.test(navigator.userAgent)) return { type: "Mobile", os: "Android" };
    if (/iPhone/.test(navigator.userAgent)) return { type: "Mobile", os: "iOS" };
    return { type: "Mobile", os: "Unknown" };
  }
  return { type: "Desktop", os: "Unknown" };
};

export const getRestrictions = (list: any, module: string) => {
  const data = list?.length && list?.filter((item: any) => item?.moduleName === module);
  const resultData = data?.[0]?.isActive;
  return resultData;
};

export async function generateSignedUrl(
  image: string,
  expiresIn = 3600,
): Promise<string> {

  const payload = {
    url: image,
  };

  const getImage = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/getSignedURL`, payload);
  const signedUrl = getImage?.data?.data;

  if (signedUrl) {
    return signedUrl;
  } else {
    return "";
  }
}