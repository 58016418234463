import React from "react";

import { UserIcon } from "../../../assets/images/icons";
import ImageLoad from "../../../components/ImageLoad";

interface RequestBoxProps {
  actions: {
    accept: boolean;
    reject: boolean;
  };
  content: {
    name: string | undefined;
    companyName: string | undefined;
    img: string | undefined;
    requestId: number;
    companyId: number | undefined;
    date: string;
  };
  onClick: (data: string) => void;
  disabled?: boolean;
}

const RequestBox = ({ actions, content, onClick, disabled = false }: RequestBoxProps) => {
  return (
    <div className="request-box">
      <div className="flex alignCenter justifyBetween request-box__inner">
        <div className="flex alignCenter w-100 gap-2">
          <div className="request-box__img-container">
            {content.img ? <ImageLoad src={content.img} alt={content.companyName ?? ""} /> : <UserIcon />}
          </div>
          <div className="request-box__text-container">
            <h4 className="title-4">{content.companyName}</h4>
            <p className="caption">{content.name}</p>
          </div>
        </div>
        {actions ?
          <div className="request-box__action-container flex alignCenter gap-3">
            {actions.reject ? <button className="action__btn-reject" disabled={disabled} onClick={() => onClick("Reject")}>Reject</button> : <></>}
            {actions.accept ? <button className="action__btn-accept" disabled={disabled} onClick={() => onClick("Accept")}>Accept</button> : <></>}
          </div>
          :
          <></>}
      </div>
    </div>
  );
};

export default RequestBox;