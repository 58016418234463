import { createSlice } from "@reduxjs/toolkit";

import { fetchTokenData } from "../services/common";

interface userDetailsData {
  company: {
    companyId: number,
    name: string,
    email: string,
    aboutCompany: string,
    city: string,
    district: string,
    state: string,
    country: string,
    pincode: string,
    createdBy: {
      userId: number,
      firstName: string,
      lastName: string,
      role: string,
      phoneNo: string
    },
    companyType: {
      businessTypeId: number,
      businessType: string
    }[],
    profileThumbnailImg: string | null,
    profileImg: string | null,
  },
  user: {
    userId: number;
    firstName: string;
    lastName: string;
    role: string;
    phoneNo: string;
    email?: string;
  }
}

interface InitialStateProps {
  userDetails: {
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    data: userDetailsData | null
  },
}

const initialState: InitialStateProps = {
  userDetails: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateUserDetail: (state, { payload }) => {
      state.userDetails.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTokenData.pending, (state) => {
      state.userDetails.isLoading = true;
      state.userDetails.isSuccess = false;
    });
    builder.addCase(fetchTokenData.fulfilled, (state, { payload }: any) => {
      state.userDetails.isSuccess = true;
      state.userDetails.isLoading = false;
      state.userDetails.data = payload?.data;
    });
    builder.addCase(fetchTokenData.rejected, (state, { payload }: any) => {
      state.userDetails.data = payload;
      state.userDetails.isSuccess = false;
      state.userDetails.isLoading = false;
      state.userDetails.message = payload?.message;
    });
  },
});

export const { updateUserDetail } = commonSlice.actions;
export default commonSlice.reducer;