import React from "react";

import { NotificationData, NotificationTypes } from "./NotificationTypes";
import { ConvertDateToTime } from "../../utils/helper";
import socket from "../../socket";
import { UserIcon } from "../../assets/images/icons";
import ImageLoad from "../ImageLoad";

interface Props {
  notification: NotificationData,
  handleAction: (value: string, type: NotificationTypes, notification: NotificationData) => void,
  loading?: boolean;
  companyId: number;
}

export const NotificationCard = ({ notification, handleAction, loading = false, companyId }: Props) => {

  const handleReadNotification = () => {
    socket.emit("readNotification", notification.notificationHistoryId, companyId);
  };

  return (
    <div className={`notifcation-card-container flex direction-column gap-1 ${notification?.isRead ? "" : "un-read"}`} onClick={handleReadNotification}>
      <div className="notification-header flex gap-2 alignCenter">
        <h3 className={(notification.notificationTypes === NotificationTypes.CONNECTION_REQUEST_REJECTED) || (notification.notificationTypes === NotificationTypes.ORDER_REJECTED_BY_VENDOR) ? "red-text" : ""}>{notification?.notificationTypes}</h3>
        {notification?.isNew && <span className="new-label">NEW</span>}
      </div>
      <div className="flex alignStart gap-2">
        <div className="image-container">
          {notification.senderImage ? <ImageLoad src={notification.senderImage} alt="user_image"/> : <UserIcon />}
        </div>
        <div className="notification-info-container flex direction-column gap-2">
          <div className="header-box">
            <h4 className="title">{notification.senderName}</h4>
            <div className="info product-notification">{notification.message}</div>
            {/* <div className="info product-notification">The rate for <b>Product Name</b> has changed. New rate is <b>₹10,00,000 /</b> pcs</div> */}
          </div>
          {notification?.actionParams ?
            // {(notification.notificationTypes !== NotificationTypes.CONNECTION_REQUEST_REJECTED && notification.notificationTypes !== NotificationTypes.CONNECTION_REQUEST_ACCEPTED) &&
            <div className="action-container flex gap-3">
              <button
                className={`reject-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-reject" : ""}`}
                onClick={(e) => [e.stopPropagation(), handleAction("Reject", notification.notificationTypes, notification)]}
                disabled={loading}>
                Reject</button>
              <button
                className={`accept-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-accept" : ""}`}
                onClick={(e) => [e.stopPropagation(), handleAction("Accept", notification.notificationTypes, notification)]}
                disabled={loading}>
                Accept</button>
            </div> : <></>}
          <span className="notification-time-text">
            {ConvertDateToTime(notification.createdOn)}
          </span>
        </div>
      </div>
    </div>
  );
};

export const PushNotificationCard = ({ notification, handleAction }: Props) => {
  
  return (
    <div className={`notifcation-card-container flex direction-column gap-1 ${notification?.isRead ? "" : "un-read"}`}>
      <div className="notification-header flex gap-2 alignCenter">
        <h3>{notification?.notificationTypes}</h3>
        {notification?.isNew && <span className="new-label">NEW</span>}
      </div>
      <div className="flex alignStart gap-2">
        <div className="image-container">
          {notification.senderImage ? <ImageLoad src={notification.senderImage} alt="user_image" /> : <UserIcon />}
        </div>
        <div className="notification-info-container flex direction-column gap-2">
          <div className="header-box">
            <h4 className="title">{notification.senderName}</h4>
            <div className="info product-notification">{notification.message}</div>
            {/* <div className="info product-notification">The rate for <b>Product Name</b> has changed. New rate is <b>₹10,00000 /</b> pcs</div> */}
          </div>
          {notification?.actionParams ?
            // {(notification.notificationTypes !== NotificationTypes.CONNECTION_REQUEST_REJECTED && notification.notificationTypes !== NotificationTypes.CONNECTION_REQUEST_ACCEPTED) &&
            <div className="action-container flex gap-3">
              <button
                className={`reject-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-reject" : ""}`}
                onClick={(e) => [e.stopPropagation(), handleAction("Reject", notification.notificationTypes, notification)]}>
                Reject</button>
              <button
                className={`accept-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-accept" : ""}`}
                onClick={(e) => [e.stopPropagation(), handleAction("Accept", notification.notificationTypes, notification)]}>
                Accept</button>
            </div> : <></>}
          <span className="notification-time-text">
            {ConvertDateToTime(notification.createdOn)}
          </span>
        </div>
      </div>
    </div>
  );
};