import React from "react";
import { Spin } from "antd";

import Breadcrumbs from "../breadcrumb";
import { ProductsGroupIcon } from "../../assets/images";
import { PlusCircleFilled } from "../../assets/images/icons";
import ImageLoad from "../ImageLoad";

interface IProps {
  children: React.ReactNode,
  title: Array<BreadcrumbItem>,
  header?: {
    pageTitle: string | React.ReactNode,
    profileIcon?: any,
    profileTitle?: string,
    buttons?: {
      addVendor?: boolean
      addProduct?: boolean
      saveProduct?: boolean
      cart?: boolean
      onClick?: () => void
      createGroup?: boolean
      onCreate?: () => void
    }
    loading?: boolean
  },
  className?: string
}

interface BreadcrumbItem {
  title: string | React.ReactNode;
  href?: string;
}

interface ISubHeaderProps {
  props?: {
    pageTitle: string | React.ReactNode,
    profileIcon?: any,
    profileTitle?: string,
    buttons?: {
      addVendor?: boolean
      addProduct?: boolean
      saveProduct?: boolean
      cart?: boolean
      onClick?: () => void
      createGroup?: boolean
      onCreate?: () => void
    },
    children?: React.ReactNode,
    loading?: boolean
  }
}

const SubHeader = ({ props }: ISubHeaderProps) => {
  return (
    <div className="flex header-container alignStart">
      <div className="flex profile-box gap-4">
        {props?.profileIcon && <ImageLoad src={props.profileIcon} alt="profile-icon" />}
        {props?.profileTitle && <h3 className="profile-title">{props.profileTitle}</h3>}
        {props?.pageTitle && <h2>{props?.pageTitle}</h2>}
        {props?.children && <>{props?.children}</>}
      </div>
      {
        props?.buttons?.addProduct &&
        <button type="button" className="rounded-14 primary large add-product" onClick={props.buttons.onClick}>
          {props?.loading ? <Spin /> : <><PlusCircleFilled /> Add Product</>}
        </button>
      }
      {
        props?.buttons?.addVendor &&
        <button type="button" className="rounded-14 primary large add-vender" onClick={props.buttons.onClick}>
          <PlusCircleFilled /> Add Vendor
        </button>
      }
      {
        props?.buttons?.createGroup &&
        <button type="button" className="rounded-14 large bg-light gap-1" onClick={props.buttons.onCreate}>
          <ProductsGroupIcon /> Create Group
        </button>
      }
      {
        props?.buttons?.saveProduct &&
        <button type="button" className="rounded-14 primary large save-product" onClick={props.buttons.onClick} disabled={props?.loading}>
          {props?.loading ? <><Spin /> Saving...</> : "Save Product"}
        </button>
      }
    </div>
  );
};

const Page = ({ children, title, header, className }: IProps) => {
  return (
    <div className={className ? className + " container page" : "container page"}>
      {header || title?.length ? <div className="header-section flex direction-column">
        {title?.length ? <Breadcrumbs items={title} /> : <></>}
        {header ? <SubHeader props={header} /> : <></>}
      </div> : <></>}
      {children}
    </div>
  );
};

export default Page;