import React, { CSSProperties, forwardRef, useCallback, useEffect, useState } from "react";
import axios from "axios";

import { NoImagesIcon, UserIcon } from "../../assets/images/icons";

interface ImageLoaderProps {
  alt: string;
  src: string;
  placeHolderImage?: string;
  className?: string;
  iconType?: string;
  style?: CSSProperties;
}

const ImageLoad = forwardRef<HTMLImageElement, ImageLoaderProps>(
  ({ alt, src, placeHolderImage, className, iconType, style }, ref) => {
    const [signedUrl, setSignedUrl] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
      if (src) {
        const fetchSignedUrl = async () => {
          try {
            const payload = {
              url: src,
            };
            const getImage = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/getSignedURL`, payload);
            setSignedUrl(getImage.data.data);
            setIsError(false);
          } catch (error) {
            console.error("Error generating signed URL:", error);
          }
        };
        fetchSignedUrl();
      }
    }, [src]);

    const handleError = useCallback(() => {
      setIsError(true);
    }, []);

    return signedUrl && !isError ? (
      <img
        className={className}
        src={signedUrl}
        alt={alt}
        ref={ref}
        loading="lazy"
        onError={handleError}
        style={style}
      />
    ) : placeHolderImage ? (
      <img src={placeHolderImage} alt={alt} className={className} loading="lazy" />
    ) : (
      iconType === "product" ?
        <NoImagesIcon /> :
        iconType === "productText" ?
          <div className="image-container flex w-100"> <div className="flex direction-column alignCenter gap-2 no-images-icon">
            <NoImagesIcon />
            <h3>No images available</h3>
          </div></div> :
          <UserIcon />
    );
  },
);

ImageLoad.displayName = "ImageLoad";
export default ImageLoad;
