import React from "react";

import AccountsList from "./accountsList";

const UserRollPage = () => {
  return (
    <div className="user_roll_page">
      <div className="user_roll_header">
        <h3 className="title-3">User and Permissions</h3>
        <p className="caption">Create distinct user roles that align with organizational workflows and security protocols.</p>
      </div>
      <AccountsList />
    </div>
  );
};

export default UserRollPage;