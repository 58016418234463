import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import { formatRupees } from "../../utils/helper";
import SubscriptionPlanSkeleton from "../../components/skeletons/subscriptionPlanSkeleton";
import { CheckMarkIcon, CrossIcon2 } from "../../assets/images";
import { ACCOUNT_SETTINGS, ERROR_STRINGS } from "../../utils/constant";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getPaymentDetailsSummary } from "../../redux/services/subscription";
import ImageLoad from "../../components/ImageLoad";
import { CleverTapEvents } from "../../utils/clevertapEvents";

const SubscriptionBody = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { planDetails } = useAppSelector((state) => state.subscription);
  const { userDetails } = useAppSelector((state) => state.common);

  const handleChoosePlan = (planId: number) => {
    setIsLoading(true);
    dispatch(getPaymentDetailsSummary({
      id: planId,
      payload: {
        needGSTInvoice: false,
        pincode: userDetails?.data?.company?.pincode || "",
      },
    })).then((result) => {
      if (result?.payload?.status === 200) {
        setIsLoading(false);
        navigate(`${planId}`);
      } else {
        setIsLoading(false);
        message.error(ERROR_STRINGS.SOMETHING_WRONG);
      }
    }).catch((error) => {
      setIsLoading(false);
      message.error(ERROR_STRINGS.SOMETHING_WRONG);
    });
  };
  return (
    <>
      <div className="subscription-settings-body">
        <div className="plans-container flex alignStart direction-column">
          {/* <div className="plan-column">
              <div className="plan-title flex alignCenter justifyCenter">
                <h2 className="title-2">{ACCOUNT_SETTINGS.SUBSCRIPTION_PAGE.SUBSCRIPTION_BODY.SUBSCRIPTION_TITLE}</h2>
              </div>
              <div className="plan-module-container">
                <h4 className="title-4 title-text">Features</h4>
                <ul className="modules-items">
                  <li className="item">Place Order</li>
                </ul>
              </div>
            </div> */}
          <div className="plans-header flex alignStart gap-2">
            <div className="column-1 w-25 m-auto">
              <h2 className="title-2 plan-title">{ACCOUNT_SETTINGS.SUBSCRIPTION_PAGE.SUBSCRIPTION_BODY.SUBSCRIPTION_TITLE}</h2>
            </div>

            {planDetails.data?.plans?.length ? planDetails.data?.plans?.map((plan, index) => {
              const isHigherTierActive = planDetails.data?.plans?.some(
                (p) => p.subscriptionId > plan.subscriptionId && p.isActive,
              );
              return (
                <div className={`column-${index + 2} w-25`} key={plan?.subscriptionId}>
                  <div className={index + 1 === 2 ? "plan-box blue" : index + 1 === 3 ? "plan-box yellow" : "plan-box"}>
                    <div className="img-container">
                      <ImageLoad src={plan?.image} alt="plan-image" />
                    </div>
                    <p className="plan-price flex alignCenter justifyCenter">
                      <span className="price">{formatRupees(Number(plan?.price), true, false)}</span>
                      <span className="time">&nbsp;/&nbsp;year</span>
                    </p>
                    <p className="plan-text">{plan?.supportiveText}</p>
                    <button
                      className={`plan-button${plan.isActive ? " active" : isHigherTierActive ? " hidden" : ""}`}
                      disabled={isHigherTierActive || plan.isActive || isLoading}
                      onClick={() => {
                        handleChoosePlan(plan?.subscriptionId);
                        CleverTapEvents.trackEvent("Subscription Plan Clicked", {
                          plan_name: plan?.name,
                          plan_price: plan?.price,
                          time: Date.now(),
                        });
                      }}
                    >
                      {plan.isActive ? "Current Plan" : `Upgrade to ${plan.name}`}
                    </button>
                  </div>
                </div>
              );
            }) : <>
              <SubscriptionPlanSkeleton height={200} />
              <SubscriptionPlanSkeleton height={200} />
              <SubscriptionPlanSkeleton height={200} />
            </>}
          </div>
          <div className="plans-body">
            {planDetails.data?.features?.length ? <div className="flex alignStart w-100 gap-2">
              <div className="w-25 plan-item flex alignCenter">
                <h3 className="heading-title">Features</h3>
              </div>
              <div className="w-25 plan-item flex alignCenter">
                <p className="text flex alignCenter gap-2">
                </p>
              </div>
              <div className="w-25 plan-item flex alignCenter">
                <p className="text flex alignCenter gap-2">
                </p>
              </div>
              <div className="w-25 plan-item flex alignCenter">
                <p className="text flex alignCenter gap-2">
                </p>
              </div>
            </div> : <>
            </>}
            {planDetails.data?.features?.length ? planDetails.data?.features?.map((feature, index) => {
              return (
                <div className="flex alignStart w-100 gap-2" key={index}>
                  <div className="w-25 plan-item flex alignCenter">
                    <h3 className="heading-text">{feature?.moduleName}</h3>
                  </div>
                  <div className="w-25 plan-item flex alignCenter">
                    <p className="text flex alignCenter gap-2">
                      {feature?.Free?.isActive ? <CheckMarkIcon /> : <CrossIcon2 />}
                      {feature?.Free?.subscriptionActivityDisplayText}
                    </p>
                  </div>
                  <div className="w-25 plan-item flex alignCenter">
                    <p className="text flex alignCenter gap-2">
                      {feature?.Gold?.isActive ? <CheckMarkIcon /> : <CrossIcon2 />}
                      {feature?.Gold?.subscriptionActivityDisplayText}
                    </p>
                  </div>
                  <div className="w-25 plan-item flex alignCenter">
                    <p className="text flex alignCenter gap-2">
                      {feature?.Premium?.isActive ? <CheckMarkIcon /> : <CrossIcon2 />}
                      {feature?.Premium?.subscriptionActivityDisplayText}
                    </p>
                  </div>
                </div>
              );
            }) : <div className="flex gap-2">
              <SubscriptionPlanSkeleton height={750} />
              <SubscriptionPlanSkeleton height={750} />
              <SubscriptionPlanSkeleton height={750} />
              <SubscriptionPlanSkeleton height={750} />
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionBody;