import { createSlice } from "@reduxjs/toolkit";

import { manageField, getProductUnits, getListOfProducts, getListOfProductSizes, getProductsGroups, getProductListInGroup, getAllProductsForGroup, getProductDetails, customFieldSuggesion, productNameSuggestion, getPlaceOrderProductsGroups } from "../services/products";
import { getProductListInGroupTypes, getUnitResponse, ManagementFieldsResponse, ProductGroupType, productListData, ProductSizes, selectedProductTypes } from "../../types/productsType";

interface productGroupsData {
  productGroupId: number,
  groupName: string,
  totalProducts: string,
}

interface ProductState {
  isLoading: boolean;
  isSuccess: boolean;
  managementFields: ManagementFieldsResponse | null;
  productUnits: getUnitResponse | null;
  productsList: productListData | null;
  productGroups: {
    isLoading: boolean;
    data: productGroupsData[] | null;
    isSuccess: boolean
  };
  placeorderProductGroups: productGroupsData[] | null;
  groupProductList: ProductGroupType | null;
  allProductsForGroup: getProductListInGroupTypes | null;
  selectedProductDetails: selectedProductTypes | null;
  productSizes: ProductSizes[] | null;
  error: string;
  suggestedField: any;
  productNameSuggestionData: string[];
  activeFilter: number;
}

const initialState: ProductState = {
  isLoading: false,
  isSuccess: false,
  managementFields: null,
  productUnits: null,
  productsList: null,
  productGroups: {
    isLoading: false,
    data: null,
    isSuccess: false,
  },
  placeorderProductGroups: null,
  groupProductList: null,
  allProductsForGroup: null,
  selectedProductDetails: null,
  productSizes: null,
  error: "",
  suggestedField: {
    1: null,
    2: null,
  },
  productNameSuggestionData: [],
  activeFilter: -1,
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateSelectedProductDetails: (state, { payload }) => {
      state.selectedProductDetails = payload;
    },
    clearSelectedProductDetails: (state) => {
      state.selectedProductDetails = initialState.selectedProductDetails;
    },
    clearSuggestion: (state) => {
      state.suggestedField = initialState.suggestedField;
    },
    clearProductNameSuggestion: (state) => {
      state.productNameSuggestionData = initialState.productNameSuggestionData;
    },
    clearProductData: (state) => {
      state.selectedProductDetails = initialState.selectedProductDetails;
      state.managementFields = initialState.managementFields;
      state.suggestedField = initialState.suggestedField;
    },
    clearProductSizeData: (state) => {
      state.productSizes = initialState.productSizes;
    },
    clearProductGroupsData: (state) => {
      state.placeorderProductGroups = initialState.placeorderProductGroups;
    },
    clearProductListGroup: (state) => {
      state.groupProductList = initialState.groupProductList;
    },
    setActiveFilter: (state, { payload }) => {
      state.activeFilter = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(manageField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(manageField.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.managementFields = payload;
    });
    builder.addCase(manageField.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Get Product Unit
    builder.addCase(getProductUnits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductUnits.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productUnits = payload;
    });
    builder.addCase(getProductUnits.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Product List
    builder.addCase(getListOfProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListOfProducts.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productsList = payload?.data;
    });
    builder.addCase(getListOfProducts.rejected, (state, { payload }: any) => {
      state.error = payload?.data;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Product Size List
    builder.addCase(getListOfProductSizes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListOfProductSizes.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.productSizes = payload;
    });
    builder.addCase(getListOfProductSizes.rejected, (state, { payload }: any) => {
      state.error = payload?.data;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // Product Group

    builder.addCase(getProductsGroups.pending, (state) => {
      state.productGroups.isLoading = true;
      state.productGroups.isSuccess = true;
    });
    builder.addCase(getProductsGroups.fulfilled, (state, { payload }: any) => {
      state.productGroups.isSuccess = true;
      state.productGroups.isLoading = false;
      state.productGroups.data = payload?.productGroup;
    });
    builder.addCase(getProductsGroups.rejected, (state, { payload }: any) => {
      state.productGroups.isSuccess = false;
      state.productGroups.isLoading = false;
    });

    // getPlaceOrderProductsGroups

    builder.addCase(getPlaceOrderProductsGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlaceOrderProductsGroups.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.placeorderProductGroups = payload?.productGroup;
    });
    builder.addCase(getPlaceOrderProductsGroups.rejected, (state, { payload }: any) => {
      state.error = payload?.productGroup;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // get product list in group

    builder.addCase(getProductListInGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductListInGroup.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.groupProductList = payload?.productGroup;
    });
    builder.addCase(getProductListInGroup.rejected, (state, { payload }: any) => {
      state.groupProductList = payload?.productGroup;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // get all products for group

    builder.addCase(getAllProductsForGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllProductsForGroup.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.allProductsForGroup = payload?.data;
    });
    builder.addCase(getAllProductsForGroup.rejected, (state, { payload }: any) => {
      state.error = payload?.data;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // selected Product Details

    builder.addCase(getProductDetails.pending, (state) => {
      state.selectedProductDetails = initialState.selectedProductDetails;
      state.isLoading = true;
      state.selectedProductDetails = null;
    });
    builder.addCase(getProductDetails.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.selectedProductDetails = payload?.data;
    });
    builder.addCase(getProductDetails.rejected, (state, { payload }: any) => {
      state.error = payload?.data;
      state.isSuccess = false;
      state.isLoading = false;
    });

    builder.addCase(customFieldSuggesion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(customFieldSuggesion.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.suggestedField = payload;
    });
    builder.addCase(customFieldSuggesion.rejected, (state, { payload }: any) => {
      state.error = payload?.data;
      state.isSuccess = false;
      state.isLoading = false;
    });

    // product Name suggestion
    builder.addCase(productNameSuggestion.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(productNameSuggestion.fulfilled, (state, { payload }: any) => {
      state.isSuccess = true;
      // state.isLoading = false;
      state.productNameSuggestionData = payload;
    });
    builder.addCase(productNameSuggestion.rejected, (state, { payload }: any) => {
      state.error = payload?.data;
      state.isSuccess = false;
      // state.isLoading = false;
    });

  },
});

export const { clearSuggestion, clearProductNameSuggestion, clearSelectedProductDetails, updateSelectedProductDetails, clearProductData, setActiveFilter, clearProductSizeData, clearProductGroupsData, clearProductListGroup } = productSlice.actions;
export default productSlice.reducer;
