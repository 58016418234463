import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OneSignal from "react-onesignal";
import { Spin } from "antd";
import axios from "axios";
import Player from "lottie-react";

import useMessageHook from "../hooks/useMessageHook";
import endPoints from "../redux/services/endPoints.json";
import { Header } from "../components/header";
import CreateCompany from "./createCompany";
import ConfirmationPopup from "../components/actionModals/confirmationPopup";
import NotificationDrawer from "../components/notification/notificationDrawer";
import { useAppSelector } from "../hooks/useAppSelector";
import { clearNotificationdata, notificationOrderData } from "../redux/slices/notificationSlice";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getCompanyDetails } from "../redux/services/company";
import { fetchTokenData, registerDeviceToken } from "../redux/services/common";
import PushNotification from "../components/pushNotification";
import { ERROR_STRINGS } from "../utils/constant";
import { updatePlaceOrderStatus } from "../redux/slices/placeorderSlice";

interface IProps {
  children?: React.ReactNode
}

const Layout = ({ children }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const createNewCompany = localStorage.getItem("createNewCompany");
  const isDraftProduct = localStorage.getItem("isDraftProduct");
  const productDataSource = localStorage.getItem("dataSource");
  const { showMessage, contextHolder } = useMessageHook();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { isNotificationDrawerOpen } = useAppSelector((state) => state.notification);
  const { placeOrderData } = useAppSelector((state) => state.placeorder);
  const { userDetails } = useAppSelector((state) => state.common);
  const { userId } = userDetails.data?.user || { userId: 0 };

  const dispatch = useAppDispatch();

  const companyId = userDetails.data?.company?.companyId;

  useEffect(() => {
    if (isDraftProduct && location.pathname !== "/products/add") {
      setIsDraft(JSON.parse(isDraftProduct));
    }
  }, [isDraftProduct, location?.pathname]);

  const handleRedirection = (event: any) => {
    if (event.notification.additionalData.moduleName === "Connection Notifications") {
      navigate("/connection");
    }
    if (event.notification.additionalData.moduleName === "Order Notifications") {
      navigate("/orders");
      dispatch(notificationOrderData(event.notification.additionalData));
    }
  };

  useEffect(() => {
    OneSignal.Notifications.addEventListener("click", (event: any) => {
      handleRedirection(event);
    });

    return () => {
      OneSignal.Notifications.removeEventListener("click", (event: any) => {
        handleRedirection(event);
      });
    };
  }, []);

  useEffect(() => {
    const createNewCompany = localStorage.getItem("createNewCompany");
    const loginCompany = createNewCompany && JSON.parse(createNewCompany || "");
    try {
      if (!loginCompany) {
        dispatch(fetchTokenData());
        dispatch(getCompanyDetails());
        setIsLoading(false);
      }
    } catch (error) {
      showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearNotificationdata());
  }, [companyId, dispatch]);

  const oneSignalToken = localStorage.getItem("fcm_token");

  useEffect(() => {
    const placeorderTimeOut = setTimeout(() => {
      dispatch(updatePlaceOrderStatus(false));
    }, 3000);

    return () => {
      clearTimeout(placeorderTimeOut);
    };
  }, [placeOrderData.isSuccess, dispatch]);

  useEffect(() => {
    const id = OneSignal.User.PushSubscription.id;
    if (id && userId && oneSignalToken) {
      OneSignal.login(id);

      const payload = {
        token: id,
        from: "Web",
      };
      dispatch(registerDeviceToken({ id: userId, payload: payload }));
    }
  }, [oneSignalToken, userId, dispatch]);

  const handleClose = () => {
    localStorage.removeItem("isDraftProduct");
    localStorage.removeItem("dataSource");
    setIsDraft(false);
  };

  const handleAddToDraft = async () => {
    if (productDataSource) {
      const { product, productData, token } = JSON.parse(productDataSource);
      const formData = new FormData();
      product?.forEach((file: string | Blob) => {
        formData.append("productImages", file);
      });
      const modifiedData = { ...productData, isDraft: true };
      formData.append("productData", JSON.stringify(modifiedData));
      await axios.post(baseURL + endPoints.addProduct, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      }).then((result) => {
        if (result.status === 200) {
          navigate(location.pathname, { replace: true });
          handleClose();
        } else {
          showMessage("error", result?.data?.message);
        }
      }).catch((errr) => { showMessage("error", errr.response?.data?.message); });
    }
  };

  if (isLoading) {
    return <div className="page-loader"><Spin /></div>; // or a spinner component
  }

  return (
    <div className="layout">
      <Header />
      {companyId ? <PushNotification /> : <></>}
      {contextHolder}
      {!isDraft ?
        <>
          {/* <button onClick={handleLogout}>Logout</button> */}
          <div className="content">
            {(createNewCompany && JSON.parse(createNewCompany)) ? <CreateCompany /> : <Outlet />}
          </div>
        </>
        : <>
          <ConfirmationPopup
            headerContent="Save as Draft"
            open={isDraft || false}
            close={() => handleClose()}
            confirm={() => handleAddToDraft()}
            infoContent="Are you sure you want to leave without saving your product?"
            cancelButtonText="Yes, Leave"
            confirmButtonText="Save Draft"
          />
        </>
      }
      {(isNotificationDrawerOpen && companyId) ? <NotificationDrawer /> : <></>}
    </div>
  );
};

export default Layout;