import React from "react";
import { isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { AppQRCode } from "./assets/images";
import Page from "./components/page";
import { Button } from "./components";
import { detectDevice } from "./utils/helper";


const DownloadApp = () => {
  const navigate = useNavigate();
  if (detectDevice()?.type === "Mobile") {
    if (detectDevice()?.os === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=app.biizline&pcampaignid=web_share";
    } else if (detectDevice()?.os === "iOS") { ///iPad|iPhone|iPod/
      window.location.href = "https://apps.apple.com/in/app/biizline/id6599846985";
    } else {
      window.location.href = "https://play.google.com/store/apps/details?id=app.biizline&pcampaignid=web_share";
    }
  } else if (isTablet) {
    window.location.href = "https://play.google.com/store/apps/details?id=app.biizline&pcampaignid=web_share";
  }

  return (
    <Page
      title={[]}
      className="download-app-page"
    >
      {detectDevice()?.type === "Desktop" ? <div className="flex direction-column gap-8">
        <h2 className="title-1 center">
          Download Biizline App
        </h2>
        <div className="img-container">
          <img src={AppQRCode} alt="QR Code" />
        </div>
        <Button type="button" className="primary" onClick={() => navigate("/login")}>Go to Web Application</Button>
      </div> : ""}
    </Page>
  );
};

export default DownloadApp;