import React, { useEffect } from "react";
import OneSignal from "react-onesignal";
import clevertap from "clevertap-web-sdk";

import Routing from "./router/router";
import socket from "./socket";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAppSelector } from "./hooks/useAppSelector";
import { registerDeviceToken } from "./redux/services/common";
import { allNotificationData, readAllNotifications } from "./redux/slices/notificationSlice";
import { ThemeProvider } from "./utils/themeContext";
import { NotificationData } from "./components/notification/NotificationTypes";
import "./common.css";
import "./assets/styles/common.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { cleverTapInit } from "./utils/clevertapEvents";

function App() {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state) => state.common);

  const { userId } = userDetails.data?.user || { userId: 0 };
  // appId: "4481c93f-2050-4c7c-b5c6-c5dd871dc1a7",
  // safari_web_id: "web.onesignal.auto.07146831-e5cf-4801-bde7-1fd4b21ac045",
  useEffect(() => {
    OneSignal.init({
      appId: "53d835ae-12b8-4caf-98ae-79644a585f8b",
      safari_web_id: "web.onesignal.auto.1ad4c923-47e5-493e-8349-b8f3b81ccfd9",
      notifyButton: {
        enable: false,
      },
      allowLocalhostAsSecureOrigin: true,
    });
    OneSignal.Notifications.requestPermission();
  }, []);
  
  function permissionChangeListener(permission: any) {
    if (permission) {
      const id = OneSignal.User.PushSubscription.id;
      if (id) {
        OneSignal.login(id);
        if (id && userId) {
    
          const payload = {
            token: id,
            from: "Web",
          };
          dispatch(registerDeviceToken({ id: userId, payload: payload }));
        }
      }
    }
  }

  useEffect(() => {
    OneSignal.Notifications.addEventListener("permissionChange", permissionChangeListener);

    return () => {
      OneSignal.Notifications.removeEventListener("permissionChange", permissionChangeListener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    socket.on("connection", () => {
      console.info("Connected to socket server");
    });

    socket.on("notificationHistoryResponse", (data: NotificationData[]) => {
      dispatch(allNotificationData(data));
    });

    socket.on("readAllNotificationResponse", (data) => {
      if (data) {
        dispatch(readAllNotifications());
      }
    });

    return () => {
      socket.off("notificationHistoryResponse");
      socket.off("readAllNotificationResponse");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  
  clevertap.spa = true;

  clevertap.setOffline(true);
  clevertap.setOffline(false);

  useEffect(() => {
    cleverTapInit();
    clevertap.setLogLevel(3);
    clevertap.privacy.push({ useIP: true });
  }, []);

  return (
    <ThemeProvider>
      <Routing />
    </ThemeProvider>
  );
}

export default App;
